/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    callHistory: null,
    singleCallData: null,
    obcCallHistory: null,
    singleObcCallData: null,


    totalPages: null,
    totalCount: null,
    loading: false,
    singleCallDataLoading: false,
};

const slice = createSlice({
    name: 'callHistory',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },
        isSingleCallDataLoading(state, action) {
            state.singleCallDataLoading = action.payload;
        },

        setCallHistoryDataSuccess(state, action) {
            state.callHistory = action.payload.data;
            state.totalPages = action.payload.totalPages;
            state.totalCount = action.payload.totalCount;
        },
        setObcCallHistoryDataSuccess(state, action) {
            state.obcCallHistory = action.payload.data;
            state.totalPages = action.payload.totalPages;
            state.totalCount = action.payload.totalCount;
        },
        setSingleCallDataSuccess(state, action) {
            state.singleCallData = action.payload;
        },
        setSingleObcCallDataSuccess(state, action) {
            state.singleObcCallData = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getCallHistoryData({ page, limit, search, start_date, end_date }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            //adding this to show loading effect for the second page.
            const timer = page === 1 ? 500 : 1500;
            await new Promise(resolve => setTimeout(resolve, timer));

            const response = await axios.get(`/call-history/get?search=${search}&start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setCallHistoryDataSuccess(response.data))

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);

        }
        finally {
            dispatch(slice.actions.isLoading(false))

        }
    }
}
export function getSingleCallData(callHistoryId) {
    return async () => {
        try {
            dispatch(slice.actions.isSingleCallDataLoading(true))

            const response = await axios.get(`/call-history/get/${callHistoryId}`);
            dispatch(slice.actions.setSingleCallDataSuccess(response.data?.data))

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);

        }
        finally {
            setTimeout(async () => {
                dispatch(slice.actions.isSingleCallDataLoading(false));
            }, 800)
        }
    }
}

export function getObcCallHistoryData({ page, limit, search, start_date, end_date }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            //adding this to show loading effect for the second page.
            const timer = page === 1 ? 500 : 1500;
            await new Promise(resolve => setTimeout(resolve, timer));

            const response = await axios.get(`/call-history/get-outbound-history?search=${search}&start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setObcCallHistoryDataSuccess(response.data))

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);

        }
        finally {
            dispatch(slice.actions.isLoading(false))

        }
    }
}
export function getSingleObcCallData(callHistoryId) {
    return async () => {
        try {
            dispatch(slice.actions.isSingleCallDataLoading(true))

            const response = await axios.get(`/call-history/get-outbound-history/${callHistoryId}`);
            dispatch(slice.actions.setSingleObcCallDataSuccess(response.data?.data))

            return response.data?.data;
        }
        catch (err) {
            throw err
        }
        finally {
            setTimeout(async () => {
                dispatch(slice.actions.isSingleCallDataLoading(false));
            }, 800)
        }
    }
}
