/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';


const initialState = {
    error: null,
    paymentData: null,
    paymentSessionData: null,
    loading: false,
    couponLoading: false,
    couponData: null,
};

const slice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isCouponLoading(state, action) {
            state.couponLoading = action.payload;
        },

        setCouponDataSuccess(state, action) {
            state.couponData = action.payload;
        },

        setPaymentDataSuccess(state, action) {
            state.paymentData = action.payload;
        },

        //for cashfree payment
        setPaymentSessionDataSuccess(state, action) {
            state.paymentSessionData = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createPaymentSessionId(data) {
    return async () => {
        try {
            const response = await axios.post(`/payment/cashfree/create-order`, data);
            dispatch(slice.actions.setPaymentSessionDataSuccess(response.data?.data))
            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
    }
}

export function createRazorpayPaymentSessionId(data) {
    return async () => {
        try {
            const response = await axios.post(`/payment/razorpay/create-order`, data);
            dispatch(slice.actions.setPaymentSessionDataSuccess(response.data?.data))
            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);

        }
    }
}

export function getOrderDetails(orderId) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.post(`/payment/cashfree/get-order?order_id=${orderId}`);
            dispatch(slice.actions.setPaymentDataSuccess(response.data?.data))
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function getRazorpayOrderDetails(orderId) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/payment/razorpay/get-order?order_id=${orderId}`);
            dispatch(slice.actions.setPaymentDataSuccess(response.data?.data))
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function initiateSubscription(data) {
    //id -> subscriptionPlan id or plan id
    return async () => {
        try {
            const response = await axios.post(`/subscription/buy-plan`, data);
            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
    }
}

export function createSubscriptionForPlan(data) {
    return async () => {
        try {
            const response = await axios.post(`/payment/razorpay/create-subscription`, data);
            dispatch(slice.actions.setPaymentSessionDataSuccess(response.data?.data))
            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
    }
}

export function getCouponData(coupon_code) {
    return async () => {
        try {
            dispatch(slice.actions.isCouponLoading(true))

            const response = await axios.get(`/coupon/apply?coupon_code=${coupon_code}`);
            dispatch(slice.actions.setCouponDataSuccess(response.data?.data))
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isCouponLoading(false))
        }
    }
}
