/* eslint-disable */
import React from 'react'
import AuthLogin from "./AuthLogin";
import Styles from "../../../styles/dialog.module.scss"
import Logo from '../../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

function SignIn(props) {

    const navigate = useNavigate()

    return (
        <div className={Styles.loginform}>
            <div name='logo' className={`text-[#000000B3] mb-5 flex items-center gap-1 ${Styles.logo} px-5 lg:px-6`}>
                <img src={Logo} alt="logo" style={{ height: '40px', width: '40px' }} />
                <p className='text-xl font-bold'>
                    <span className='text-[#133674]'>Ring</span>
                    <span className='text-[#169CD9]'>Us</span>
                </p>
            </div>
            <div className='px-5 lg:px-10 mt-[80px]'>
                <AuthLogin />
                <div className='flex items-center text-[#000000B3] justify-center gap-3 mt-8'>
                    <p>Don't have an account?{' '}
                        <span
                            className='text-blue-500 cursor-pointer'
                            role='button'
                            onClick={() => navigate('/sign-up')}
                        >
                            Sign up
                        </span>
                    </p>
                </div>
            </div>

        </div>
    )
}

export default SignIn
