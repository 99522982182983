export const StepOneContent = () => {
    return (
        <div className="flex flex-col gap-4">
            <h4 className="text-lg font-bold">Manage Your Numbers</h4>
            <p>Here, you can view, purchase, and manage numbers for your AI agents.</p>
        </div>
    )
}
export const StepTwoContent = () => {
    return (
        <div className="flex flex-col gap-4">
            <h4 className="text-lg font-bold">Buy a Dedicated Number</h4>
            <p>Purchase a dedicated number for your AI agent by clicking here.</p>
        </div>
    )
}
export const StepThreeContent = () => {
    return (
        <div className="flex flex-col gap-4">
            <h4 className="text-lg font-bold">Find Your Preferred Number</h4>
            <p>Use the search box to find available numbers by country.</p>
        </div>
    )
}
export const StepFourContent = () => {
    return (
        <div className="flex flex-col gap-4">
            <h4 className="text-lg font-bold">Manage Your Assistants</h4>
            <p>This section allows you to manage and customize your AI agents.</p>
        </div>
    )
}
export const StepFiveContent = () => {
    return (
        <div className="flex flex-col gap-4">
            <h4 className="text-lg font-bold">Create a new Agent</h4>
            <p>Create your custom agent or choose from a variety of Ready-made templates.</p>
        </div>
    )
}
export const StepSixContent = () => {
    return (
        <div className="flex flex-col gap-4">
            <h4 className="text-lg font-bold">Manage Settings</h4>
            <p>Manage the setting of a phone number by assigning it to an agent.</p>
        </div>
    )
}
export const StepSevenContent = () => {
    return (
        <div className="flex flex-col gap-4">
            <h4 className="text-lg font-bold">Assign Agent</h4>
            <p>When you assign this number to an agent then the agent will respond on behalf of you.</p>
        </div>
    )
}