import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import { useSelector } from '../../store';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
    const { isLoggedIn } = useSelector((state)=> state.account)
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            window.location.href = '/login';
        }
    }, [isLoggedIn, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
