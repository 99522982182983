import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useSelector } from '../../store';
import NoPermissionPage from '../../views/pages/others/NoPermissionPage';

//Read guard
export const AssistantsReadGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isReadAllowed = member_permission?.assistants?.view_assistants

    return isReadAllowed ? children : <NoPermissionPage />
};

AssistantsReadGuard.propTypes = {
    children: PropTypes.node
};

//Write guard
export const ViewAgentsGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isReadAllowed = member_permission?.assistants?.view_agents

    return isReadAllowed ? children : <NoPermissionPage />
};

ViewAgentsGuard.propTypes = {
    children: PropTypes.node
};

//Buy Number guard
export const CreateAgentGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isWriteAllowed = member_permission?.assistants?.create_agent

    return isWriteAllowed ? children : <NoPermissionPage />
};

CreateAgentGuard.propTypes = {
    children: PropTypes.node
};

