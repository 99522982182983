
import Navbar from './Navbar';
import Footer from './Footer';
import SecondNavbar from './SecondNavbar';

function LandingPage({ children, type }) {

  return (
    <>
      {type === 'second' ?
        <SecondNavbar />
        :
        <Navbar />
      }
      <div className="App overflow-x-hidden max-w-[86rem] mx-auto">
        {children}
      </div>
      
      <Footer />
    </>
  );
}

export default LandingPage;
