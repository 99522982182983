import React from 'react';

const RefundPolicyPage = () => {
    return (
        <>
            <div className="max-w-4xl mx-auto p-8 mt-20">
                <h1 className="text-3xl font-bold mb-6">Cancellation and Refund Policy </h1>
                <p className="mb-4">Last Updated: 17th March 2023</p>
                <p className="mb-4">
                    At Ringus.AI, operated by Techlane AI Private Limited, we strive to ensure our customers are satisfied with their purchases. However, we understand that there may be occasions when you need to cancel your plan or request a refund. Please review our policy below for details.
                </p>

                <h2 className="text-2xl font-semibold mb-4">Refunds</h2>
                <h3 className="text-xl font-semibold mb-2 ml-4">1. Eligibility</h3>
                <p className="mb-4 ml-8">
                    A refund can be requested within 2 days of purchasing a plan.
                </p>
                <h3 className="text-xl font-semibold mb-2 ml-4">2. Process</h3>
                <p className="mb-4 ml-8">
                    <ul className="list-disc list-inside">
                        <li>To request a refund, please write an email to our support team at admin@techlane.ai, including your order details and the reason for the refund request.</li>
                        <li>Once your refund request is received, it will be reviewed and processed within 5 business days if approved.</li>
                    </ul>
                </p>
                <h3 className="text-xl font-semibold mb-2 ml-4">3. Conditions</h3>
                <p className="mb-4 ml-8">
                    <ul className="list-disc list-inside">
                        <li>Refunds will only be issued to the original payment method used for the purchase.</li>
                        <li>Refunds are not available for any plan after 2 days from the date of purchase.</li>
                    </ul>
                </p>

                <h2 className="text-2xl font-semibold mb-4">Cancellations</h2>
                <h3 className="text-xl font-semibold mb-2 ml-4">1. Cancellation Policy</h3>
                <p className="mb-4 ml-8">
                    <ul className="list-disc list-inside">
                        <li>Users can cancel their plan at any time.</li>
                        <li>To cancel your plan, please send an email to admin@techlane.ai with your account and plan details.</li>
                    </ul>
                </p>
                <h3 className="text-xl font-semibold mb-2 ml-4">2. Refund for Cancellations</h3>
                <p className="mb-4 ml-8">
                    <ul className="list-disc list-inside">
                        <li>If a plan is cancelled within 2 days of purchase, a refund request can be made as per the refund policy mentioned above.</li>
                        <li>There will be no refunds if the plan is cancelled after two days of purchase.</li>
                    </ul>
                </p>

                <section className="mb-6 mt-6">
                    <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
                    <p>
                        If you have any questions about these Terms and Conditions, please contact us at:
                    </p>
                    <address className="not-italic mt-4 leading-[1.3rem]">
                        <span className='font-semibold'>Techlane AI Private Limited</span><br />
                        Unit No. 505, KLJ Tower North, Netaji Subhash Place, Pitampura, Delhi - 110034 <br />
                        <a href="mailto:admin@techlane.ai" className="text-blue-600 hover:underline">
                            admin@techlane.ai
                        </a>
                        <br />
                        +91 9773665117
                    </address>
                </section>

                <p className="mb-4">
                    We appreciate your understanding and cooperation.
                </p>
                <p className="mb-4">
                    Note: Techlane AI Private Limited reserves the right to amend this policy at any time. Any changes will be posted on this page.
                </p>
                <p className="mb-4">
                    Thank you for choosing Ringus.AI.
                </p>
            </div>
        </>
    );
};

export default RefundPolicyPage;



{/* <div className="max-w-4xl mx-auto p-6 bg-white mt-[7rem]">
                <h1 className="text-3xl font-bold mb-6">Refund Policy</h1>

                <section className="mb-6">

                    <div style={{ height: '100vh', width: '100%' }}>
                        <iframe
                            src={'https://ringus.s3.ap-south-1.amazonaws.com/ringus_rnc.pdf'}
                            width="100%"
                            height="100%"
                            style={{
                                borderBottom: '10px solid',
                                borderLeft: '10px solid',
                                borderRight: '10px solid',
                                borderRadius: '5px'
                            }}
                        />
                    </div>

                </section>
                <section className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
                    <p>
                        If you have any questions about these Terms and Conditions, please contact us at:
                    </p>
                    <address className="not-italic mt-4 leading-[1.3rem]">
                        <span className='font-semibold'>Techlane AI Private Limited</span><br />
                        Unit No. 505, KLJ Tower North, Netaji Subhash Place, Pitampura, Delhi - 110034 <br />
                        <a href="mailto:admin@techlane.ai" className="text-blue-600 hover:underline">
                            admin@techlane.ai
                        </a>
                        <br />
                        +91 9773665117
                    </address>
                </section>
            </div> */}