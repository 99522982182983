/* eslint-disable no-plusplus */

import axios from "axios";
import UAParser from 'ua-parser-js';
import { v4 as UUIDv4 } from 'uuid';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    return {
        sx: {
            color: '#fff',
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1]?.[0] || ''}`,
    };
}

export function removeOccurrencesExceptFirst(inputString, character) {
    let firstFound = false;
    let result = '';
    for (let i = 0; i < inputString.length; i++) {
        if (inputString[i] !== character) {
            result += inputString[i];
        } else if (!firstFound) {
            result += character;
            firstFound = true;
        }
    }
    return result;
}

export function getCookie(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length == 2) return parts.pop().split(';').shift();
}


export async function getDeviceDetails() {
    const response = await axios.get('https://api.ipify.org?format=json')
    const ip_address = response.data?.ip || 'Unknown';

    const parser = new UAParser();
    const device_name = parser.getBrowser()?.name;
    const platform = parser.getOS()?.name;

    return { ip_address, device_name, platform }
}


export function generateUniqueId() {
    const id = UUIDv4();
    return id;
}
