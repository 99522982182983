/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    show: false,
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const overlayLoading = createSlice({
    name: 'overlayLoading',
    initialState,
    reducers: {
        showOverlayLoading(state){
            state.show = true;
        },

        hideOverlayLoading(state) {
            state.show = false;
        }
    }
});

export default overlayLoading.reducer;

export const {showOverlayLoading,  hideOverlayLoading} = overlayLoading.actions;
