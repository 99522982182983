
// SnackbarStack.js
import React from 'react';
import { Alert, IconButton, Slide, Snackbar as MuiSnackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from '../store';
import { closeSnackbar } from '../store/slices/snackbar';

const animations = {
    SlideLeft: (props) => <Slide {...props} direction="left" />,
    SlideUp: (props) => <Slide {...props} direction="up" timeout={{ enter: 400, exit: 300 }} />,
    SlideRight: (props) => <Slide {...props} direction="right" />,
    SlideDown: (props) => <Slide {...props} direction="down" />,
};

const SnackbarStack = () => {
    const dispatch = useDispatch();
    const { snackbars } = useSelector((state) => state.snackbar);

    const handleClose = (event, reason, id) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar(id));
    };

    return (
        <>
            {snackbars.map(({ id, message, anchorOrigin, variant, alert, transition, close }, index) => (
                <MuiSnackbar
                    key={id}
                    anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'center' }}
                    open={true}
                    autoHideDuration={4000}
                    onClose={(event, reason) => handleClose(event, reason, id)}
                    TransitionComponent={animations[transition] || animations.SlideUp}
                    sx={{
                        width: { xs: '90%', sm: '500px' },
                        borderRadius: '5px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        mb: `${index * 54}px`
                    }}
                >
                    {variant === 'alert' ? (
                        <Alert
                            variant={alert.variant}
                            color={alert.color}
                            severity={alert.color}
                            action={
                                <>
                                    {close !== false && (
                                        <IconButton
                                            size="small"
                                            aria-label="close"
                                            onClick={(event) => handleClose(event, 'closeButton', id)}
                                            sx={{ color: 'background.paper' }}
                                        >
                                            <CloseIcon fontSize="small" className='font-bold' />
                                        </IconButton>
                                    )}
                                </>
                            }
                            icon={false}
                            sx={{
                                backgroundColor: alert.color === 'error' ? '#EF4146' : '#169cd9',
                                color: 'white',
                                ...(alert.variant === 'outlined' && {
                                    bgcolor: 'background.paper'
                                })
                            }}
                        >
                            {message}
                        </Alert>
                    ) : (
                        <div>
                            {message}
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={(event) => handleClose(event, 'closeButton', id)}
                                sx={{ mt: 0.25 }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </div>
                    )}
                </MuiSnackbar>
            ))}
        </>
    );
};

export default SnackbarStack;

