/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    agents: null,
    singleAgent: null,
    agentTemplates: null,
    llmModelData: null,
    ttsModelData: null,
    ttsModelProviders: null,
    providerVoiceIds: null,
    agentAnalyticsData: null,

    loading: false,
    templatesLoading: false,
    createAgentLoading: false,
    singleAgentLoading: false,
    updateAgentLoading: false,
    ttsModelDataLoading: false,
    llmModelDataLoading: false,
    agentAnalyticsDataLoading: false,
};

const slice = createSlice({
    name: 'agent',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isTemplatesLoading(state, action) {
            state.templatesLoading = action.payload;
        },

        isCreateAgentLoading(state, action) {
            state.createAgentLoading = action.payload;
        },

        isUpdateAgentLoading(state, action) {
            state.updateAgentLoading = action.payload;
        },

        isSingleAgentLoading(state, action) {
            state.singleAgentLoading = action.payload;
        },

        isLlmModelDataLoading(state, action) {
            state.llmModelDataLoading = action.payload;
        },

        isAgentAnalyticsDataLoading(state, action) {
            state.agentAnalyticsDataLoading = action.payload;
        },

        isTtsModelDataLoading(state, action) {
            state.ttsModelDataLoading = action.payload;
        },

        setAgentDataSuccess(state, action) {
            state.agents = action.payload;
        },

        setAgentTemplatesDataSuccess(state, action) {
            state.agentTemplates = action.payload;
        },

        setSingleAgentDataSuccess(state, action) {
            state.singleAgent = action.payload;
        },

        setLlmModelDataSuccess(state, action) {
            state.llmModelData = action.payload;
        },

        setTtsModelDataSuccess(state, action) {
            state.ttsModelProviders = action.payload?.providers;
            state.providerVoiceIds = action.payload?.voiceIds;
            state.ttsModelData = action.payload?.data;
        },

        createAgentDataSuccess(state, action) {
            state.agents = state?.agents ? state?.agents : [];
            state.agents = [...state?.agents, action.payload];
        },

        updateAgentDataSuccess(state, action) {
            const index = state.agents.findIndex(agent => agent._id === action.payload.id);
            const agentData = state.agents[index];
            const newData = { ...agentData, ...action.payload.data };
            state.agents[index] = newData
        },

        deleteAgentDataSuccess(state, action) {
            const newAgents = state.agents.filter(agent => agent._id !== action.payload.agent_id);
            state.agents = newAgents;
        },

        setAgentAnalyticsDataSuccess(state, action) {
            state.agentAnalyticsData = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getAllAgents(type) {
    return async () => {
        let newType = type ? type : ''
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/agent/all?type=${newType}`);
            dispatch(slice.actions.setAgentDataSuccess(response.data?.data));

        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}
export function getAllAgentTemplates(type) {
    return async () => {
        let newType = type ? type : ''
        try {
            dispatch(slice.actions.isTemplatesLoading(true));

            const response = await axios.get(`/agent/templates?type=${newType}`);
            dispatch(slice.actions.setAgentTemplatesDataSuccess(response.data?.data));

        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isTemplatesLoading(false));
        }
    }
}

export function getSingleAgent(id) {
    //id -> mongoDB _id
    return async () => {
        try {
            dispatch(slice.actions.isSingleAgentLoading(true));

            const response = await axios.get(`/agent/get/${id}`);
            dispatch(slice.actions.setSingleAgentDataSuccess(response.data?.data));
            return response.data?.data

        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            setTimeout(async () => {
                dispatch(slice.actions.isSingleAgentLoading(false));
            }, 500)
        }
    }
}

export function createAgent(data) {
    //id -> mongoDB _id
    return async () => {
        try {
            dispatch(slice.actions.isCreateAgentLoading(true));

            const response = await axios.post(`/agent/create`, data);

            await new Promise(resolve => setTimeout(resolve, 2000));

            dispatch(slice.actions.createAgentDataSuccess(response.data?.data));
            handleSuccess(response, "Agent created successfully", dispatch);

            return response.data;

        }
        catch (error) {
            handleError(error, dispatch);
            throw error
        }
        finally {
            dispatch(slice.actions.isCreateAgentLoading(false));
        }
    }
}

export function updateAnAgent(data, id) {
    //id -> mongoDB _id
    return async () => {
        try {

            dispatch(slice.actions.isUpdateAgentLoading(true));

            const response = await axios.put(`/agent/update/${id}`, data);
            dispatch(slice.actions.updateAgentDataSuccess(
                { data, id }
            ));

            handleSuccess(response, "Agent updated successfully", dispatch);

            return response.data?.data
        }
        catch (error) {
            handleError(error, dispatch);
            throw error
        }
        finally {
            dispatch(slice.actions.isUpdateAgentLoading(false));
        }
    }
}

export function getLlmModelInfo() {
    return async () => {
        try {
            dispatch(slice.actions.isLlmModelDataLoading(true));

            const response = await axios.get(`/agent/llm_model`);
            dispatch(slice.actions.setLlmModelDataSuccess(response.data?.data));
            return response.data?.data;
        }
        catch (err) {
            throw err
        }
        finally {
            dispatch(slice.actions.isLlmModelDataLoading(false));
        }
    }
}
export function getTtsModelInfo({ provider, voice_id }) {
    return async () => {
        try {
            dispatch(slice.actions.isTtsModelDataLoading(true));

            const response = await axios.get(`/agent/tts_model?provider=${provider}&voice_id=${voice_id}`);
            dispatch(slice.actions.setTtsModelDataSuccess(response.data));
            return response.data?.data;
        }
        catch (err) {
            throw err
        }
        finally {
            dispatch(slice.actions.isTtsModelDataLoading(false));
        }
    }
}

export function deleteAgent(agent_id) {
    //id -> mongoDB _id
    return async () => {
        try {
            const response = await axios.delete(`/agent/delete?agent_id=${agent_id}`);
            dispatch(slice.actions.deleteAgentDataSuccess(
                { data: response.data?.data, agent_id }
            ));
            handleSuccess(response, "Agent deleted successfully", dispatch);

            return response.data?.data
        }
        catch (error) {
            handleError(error, dispatch);
        }
    }
}

// agent_id=${agent_id}&call_type=${call_type}&duration_type=${duration_type}&start_date=${start_date}&end_date=${end_date}
export function getAgentAnalyticsData({ filter_query }) {
    return async () => {
        try {
            dispatch(slice.actions.isAgentAnalyticsDataLoading(true));

            await new Promise(resolve => setTimeout(resolve, 2000));

            const response = await axios.get(`/agent/analytics?${filter_query}`);
            dispatch(slice.actions.setAgentAnalyticsDataSuccess(response.data?.data));

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isAgentAnalyticsDataLoading(false));
        }
    }
}
