
import { keyframes, styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import { IconX } from '@tabler/icons';
import Styles from "../styles/dialog.module.scss"

// Animation keyframes for smooth appearance and disappearance
const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: scale(0.90) translateY(10px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

// Styled Dialog component with custom animations and dynamic properties
const AnimatedDialog = styled(Dialog)(({ style }) => ({
  '.MuiBackdrop-root': {
    animation: `${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '.MuiPaper-root': {
    animation: `${contentShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,

    width: '450px',  // Default width is 450px
    borderRadius: '10px',  // Default border radius is 10px
    overflow: 'visible',

    //dynamic styles
    ...style,

    transition: 'transform 200ms cubic-bezier(0.16, 1, 0.3, 1), opacity 200ms cubic-bezier(0.16, 1, 0.3, 1)',
    '&.MuiDialog-paperExiting': {
      opacity: 0,
      transform: 'scale(0.90) translateY(10px)',
    },
  },
}));

// Reusable MuiDialog Component
export const MuiDialog = ({
  open,
  handleClose,
  closable = true,
  element,
  style = {}
}) => {
  const handleDialogClose = (event, reason) => {
    if (!closable && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    handleClose();
  };

  return (
    <AnimatedDialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="animated-dialog-title"
      TransitionProps={{
        timeout: { enter: 200, exit: 200 },
      }}
      style={style}
    >
      {element}
    </AnimatedDialog>
  );
};


export const DialogBox = (props) => {

  const handleClose = () => {
    window.localStorage.removeItem('showDialog');
    props.handleClose()
  }

  return (
    <AnimatedDialog
      open={props.open}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        classes: {
          root: Styles.dialog,
          borderRadius: '5px'
        }
      }}
      onClose={handleClose}
      aria-labelledby="animated-dialog-title"
      TransitionProps={{
        timeout: { enter: 200, exit: 200 },
      }}
      style={{
        overflow: 'auto'
      }}
    >
      <div className='flex w-full justify-between items-center'>
        {props.title &&
          <div>
            {props?.title}
          </div>
        }

        {props.closeIcon &&
          <IconButton onClick={handleClose}>
            <IconX />
          </IconButton>
        }
      </div>
      {props.element}
    </AnimatedDialog>
  )
}
