import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useDispatch, useSelector } from '../../store';
import Styles from '../../styles/ai_conversation.module.scss';
import live_conversation_mic from '../../assets/images/live_conversation_mic.svg'
import { AudioVisualizer } from './VoiceVisualizer';
import { IconButton } from '@mui/material';
import { IconX } from '@tabler/icons';
import { hideLiveAiConversationPopup } from '../../store/slices/liveAiConversation';

const LiveAiConversationPopUp = () => {
    const dispatch = useDispatch()
    const { show } = useSelector((state) => state.liveAiConversation)

    const [isListening, setIsListening] = useState(false);
    const [audioData, setAudioData] = useState(new Array(0).fill(0.2));
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const animationFrameRef = useRef(null);
    const mediaStreamRef = useRef(null);


    const startListening = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaStreamRef.current = stream;

            // Create audio context and analyzer
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            analyserRef.current = audioContextRef.current.createAnalyser();
            analyserRef.current.fftSize = 128;
            analyserRef.current.smoothingTimeConstant = 0.7; // Smooth transitions

            // Connect the audio stream
            const source = audioContextRef.current.createMediaStreamSource(stream);
            source.connect(analyserRef.current);

            setIsListening(true);
            updateAudioData();
        }
        catch (error) {
            console.error('Error accessing microphone:', error);
            alert('Please allow microphone access to use this feature');
        }
    };

    const stopListening = () => {
        if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach(track => track.stop());
        }
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }
        if (audioContextRef.current) {
            audioContextRef.current.close();
        }
        setIsListening(false);
        setAudioData(new Array(0).fill(0.2));
    };

    const updateAudioData = () => {
        if (!analyserRef.current) return;

        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
        analyserRef.current.getByteFrequencyData(dataArray);

        // Process the audio data for better visualization
        const normalizedData = Array.from(dataArray)
            .slice(0, 200)
            .map((value, index) => {
                // Normalize the value between 0 and 1
                const normalized = value / 255;

                // Apply frequency weighting
                const frequencyWeight = Math.sin((index / 64) * Math.PI);

                // Apply some minimal height when there's no sound
                const minHeight = 0.1;

                return minHeight + normalized * (0.3 + frequencyWeight * 0.7);
            });

        setAudioData(normalizedData);
        animationFrameRef.current = requestAnimationFrame(updateAudioData);
    };

    useEffect(() => {
        return () => {
            stopListening();
        };
    }, []);

    const handleMicClick = () => {
        if (isListening) {
            stopListening();
        }
        else {
            startListening();
        }
    };

    const handleCloseConversationInterface = () => {
        dispatch(hideLiveAiConversationPopup());
    }

    return (
        <>
            {show ?
                <div className={`${Styles.container}`}>
                    {/* Blur effect */}
                    <div className="absolute backdrop-blur-md h-screen w-full" />
                    <IconButton className='absolute top-[1.5rem] right-[1.5rem]' onClick={handleCloseConversationInterface}>
                        <IconX className="w-8 h-8 text-black" />
                    </IconButton>

                    {/* Heading */}
                    <h1 className="text-primary sm:text-xl font-medium mb-16 relative z-10 text-center">
                        Click the mic to start talking to your AI Agent Manager!
                    </h1>

                    {/* Main container for orbits */}
                    <div className={`${Styles.circle_one}`}>
                        {/* Outer orbit */}
                        <div className="absolute inset-0 animate-[spin_20s_linear_infinite]">
                            <div className={`${Styles.co_sph_one}`} />
                            <div className={`${Styles.co_sph_two}`} />
                            <div className={`${Styles.co_sph_three}`} />
                            <div className={`${Styles.co_sph_four}`} />
                            <div className="absolute w-full h-full rounded-full border-2 border-primary z-10" />
                        </div>

                        {/* Inner orbit */}
                        <div className={`${Styles.circle_two} animate-[spin_10s_linear_infinite_reverse]`}>
                            <div className={`${Styles.ct_sph_one}`} />
                            <div className={`${Styles.ct_sph_two}`} />
                            <div className="absolute w-full h-full rounded-full border-2 border-primary z-10" />
                        </div>

                        {/* Center microphone button */}
                        <button
                            onClick={handleMicClick}
                            className={`absolute top-1/2 left-1/2 -mt-8 -ml-8 w-16 h-16 rounded-full flex items-center justify-center cursor-pointer transition-colors z-20 ${isListening ? 'bg-red-500 hover:bg-red-600' : 'bg-primary hover:bg-primary-600 '
                                }`}
                        >
                            <img src={live_conversation_mic} alt='mic' className={`w-10 h-10 sm:w-14 sm:h-14 text-white ${isListening && 'animate-pulse'}`} />
                        </button>
                    </div>

                    {/* Audio wave animation */}
                    <div className="relative mt-[-108px] w-[600px] h-16 flex items-center justify-center">
                        <AudioVisualizer audioData={audioData} />
                    </div>

                    {/* History button */}
                    <button className="mt-4 px-4 py-2 bg-primary text-white rounded-full transition-colors z-20 text-sm sm:text-base">
                        Conversation History
                    </button>
                </div >

                :
                null
            }
        </>
    );
};

export default LiveAiConversationPopUp;

