/* eslint-disable */
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, ClickAwayListener } from '@mui/material';
import { useSelector, useDispatch } from '../../../store';
import { closeDetailsDrawer } from '../../../store/slices/detailsDrawer';

// ===============================|| UI DIALOG - RESPONSIVE ||=============================== //

export default function DetailsDrawer() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const { open, title, element } = useSelector((state) => state.detailsDrawer);

    const handleClose = (event) => {
        const buttonId = event?.target?.id;
        if (buttonId && buttonId === 'open_dd_button') {
            return;
        }
        dispatch(closeDetailsDrawer());
    };

    return (
        <div>
            {open &&
                <ClickAwayListener onClickAway={handleClose}>
                    <div className={`fixed right-0 ${!matchDownMd ? 'top-[4.5rem]' : 'top-[3.3rem]'} pb-[5.5rem] h-screen w-full sm:w-[30rem] bg-white shadow-lg z-50 overflow-y-auto py-6 px-4`}>
                        <div className='flex items-center justify-between px-6'>
                            <h2 className='text-lg font-semibold'>{title}</h2>
                            <button className="mb-4 text-xl" onClick={handleClose}>
                                &times;
                            </button>
                        </div>
                        <hr className='mb-2' />

                        <div className='px-6'>
                            {element}
                        </div>
                    </div>
                </ClickAwayListener>
            }
        </div>
    );
}
