import Routes from "./routes";
import { FirebaseProvider as AuthProvider } from "./contexts/FirebaseContext";
import { PermissionProvider } from "./contexts/PermissionContext";

import Locales from "./ui-elements/Locales";
import ThemeCustomization from './themes'
import ConfirmationModal from "./views/components/others/ConfirmationModal";
import Snackbar from "./ui-elements/Snackbar";
import NetworkStatus from "./ui-elements/NetworkStatus/NetworkStatus";
import LoadingOverlay from "./ui-elements/overlayLoading";
import DetailsDrawer from "./views/components/others/DetailsDrawer";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LiveAiConversationPopUp from "./views/agentLiveConversation/LiveAiConversation";
import DashboardTour from "./ui-elements/dashboardTour/DashboardTour";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeCustomization>
        <Locales>
          <AuthProvider>
            <PermissionProvider>
              <>
                <Routes />
                <ConfirmationModal />
                <DetailsDrawer />
                <Snackbar />
                <NetworkStatus />
                <LoadingOverlay />
                <LiveAiConversationPopUp />
                <DashboardTour />
              </>
            </PermissionProvider>
          </AuthProvider>
        </Locales>
      </ThemeCustomization>
    </LocalizationProvider>

  );
}

export default App;
