
// snackbarSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    snackbars: []
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSnackbar(state, action) {
            // Add new snackbar to the queue
            state.snackbars.push({
                id: new Date().getTime(), // unique id for each snackbar
                ...action.payload
            });
        },
        closeSnackbar(state, action) {
            state.snackbars = state.snackbars.filter(snackbar => snackbar.id !== action.payload);
        }
    }
});

export default snackbarSlice.reducer;
export const { closeSnackbar, openSnackbar } = snackbarSlice.actions;
