import React from 'react';
import LogoSection from '../../../layout/MainLayout/LogoSection';
import { useDispatch, useSelector } from '../../../store';
import Styles from "../../../styles/login.module.scss";
import { Grid } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import { Icon } from '@iconify/react/dist/iconify.js';
import { LoadingButton } from '@mui/lab';
import OtpInput from 'react18-input-otp';
import { useTheme } from '@mui/material/styles';
import { openSnackbar } from '../../../store/slices/snackbar';

const TwoFactorDisablePage = () => {
    const dispatch = useDispatch()
    const theme = useTheme();
    const borderColor = theme.palette.grey[400];

    const { verifyTwoFaCode } = useAuth()

    const [verifyError, setVerifyError] = React.useState('')
    const [verifyLoading, setVerifyLoading] = React.useState(false)
    const [qrCodeData, setQrCodeData] = React.useState(null);
    const [otp, setOtp] = React.useState('');

    const { data: userData } = useSelector((state) => state.account)

    //function to show messages
    const showMessage = (type, message) => {
        dispatch(openSnackbar({
            open: true,
            message: message,
            variant: 'alert',
            alert: { color: type },
            close: true
        }))
    }

    const showVerifyCodeMessage = (type, message) => {
        if (type === 'error') {
            setVerifyError(message)
            setTimeout(() => {
                setVerifyError('')
            }, 5000)
        }
    }


    //function to send otp to the user phone number through firebase
    const verifyOtp = async (otp) => {

        if (!otp || otp?.length < 6 || typeof (otp) !== 'string') {
            showVerifyCodeMessage("error", "Please enter valid OTP")
            return;
        }

        setVerifyLoading(true)
        try {
            const user_id = userData?._id;
            const twofa_enabled = false;
            await verifyTwoFaCode(otp, user_id, twofa_enabled)

            await new Promise(resolve => setTimeout(resolve, 2000));

            setVerifyLoading(false)
            showMessage("success", "Code verified successfully.")
            window.location.href = "/dashboard/user-profile?tab=settings";

        }
        catch (error) {
            console.log(error)
            setVerifyLoading(false)
            showVerifyCodeMessage("error", error?.message || "Error in verifying two factor code.")
        }
    }

    const handleOtpChange = (otpNumber) => {
        setOtp(otpNumber)
        if (otpNumber?.length >= 6) {
            verifyOtp(otpNumber)
        }
    }


    return (
        <div className="flex flex-col items-center justify-start h-screen bg-gray-100 pt-[4rem]">

            <div className='flex items-center gap-3 justify-center'>
                <LogoSection />
                <h1 className='text-2xl font-bold'>
                    Ring
                    <span className='text-[#169CD9]'>Us</span>
                </h1>
            </div>

            <div className="rounded-lg p-8 max-w-md text-center flex flex-col h-full items-center justify-center">
                <div className='my-4 text-black flex flex-col items-center justify-center gap-2'>
                    <h2 className='text-xl text-center text-black font-semibold'>Verify Your Identity</h2>
                </div>

                <Grid item xs={12} className='mt-[10px] text-black'>
                    <div id="recaptcha-container" className='my-3'></div>
                    <p className="text-md mb-2 text-black" >Enter code from the app</p>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <OtpInput
                                value={otp}
                                onChange={(otpNumber) => handleOtpChange(otpNumber)}
                                numInputs={6}
                                containerStyle={{ justifyContent: 'space-between' }}
                                inputStyle={{
                                    width: '100%',
                                    margin: '8px',
                                    padding: '10px',
                                    border: `2px solid ${borderColor}`,
                                    borderRadius: 4,
                                    ':hover': {
                                        borderColor: theme.palette.primary.main
                                    }
                                }}
                                focusStyle={{
                                    outline: 'none',
                                    border: `2px solid ${theme.palette.primary.main}`
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                disableElevation
                                fullWidth size="large"
                                type="submit"
                                variant="contained"
                                onClick={verifyOtp}
                                loading={verifyLoading}
                                disabled={verifyLoading}
                            >
                                Verify
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <div className='mt-3'>
                        {verifyError &&
                            <div className={`${Styles.error_box} p-3`}>
                                {verifyError}
                            </div>
                        }
                    </div>
                </Grid>

            </div>
        </div>
    );
};

export default TwoFactorDisablePage;
