import React, { useState } from 'react';
import { Button, Box, Divider, IconButton, FormControl, Select, MenuItem, Grid, TextField, Typography, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Styles from "../../../styles/login.module.scss";

import useAuth from '../../../hooks/useAuth'
import { LoadingButton } from '@mui/lab';
import OtpInput from 'react18-input-otp';
import { Stack } from '@mui/system';
import { getFirebaseErrorMessage } from './getFirebaseErrorMessage';
import { useSelector } from '../../../store';
import { Icon } from '@iconify/react/dist/iconify.js';

const TwoFaForm = () => {
    const theme = useTheme()
    const { saveUserData, saveUserDataGoogleLogin, verifyTwoFaCode } = useAuth()
    const { data } = useSelector((state) => state.account)

    const borderColor = theme.palette.grey[400];

    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const [otp, setOtp] = React.useState('');
    const [verifyLoading, setVerifyLoading] = React.useState(false)

    const showMessage = (type, message) => {
        if (type === 'error') {
            setError(message)
            setTimeout(() => {
                setError('')
            }, 3000)
        }
        else if (type === 'success') {
            setSuccess(message)
            setTimeout(() => {
                setSuccess('')
            }, 3000)
        }
    }



    const loginTheUser = async (otp) => {
        const { auth_type, ...restData } = data;
        const dataToSend = {
            ...restData,
            twofa_code: otp
        }

        setVerifyLoading(true)

        if (auth_type === 'email-password') {
            try {
                await saveUserData(dataToSend)
            }
            catch (error) {
                showMessage("error", error?.message || "Something went wrong. Please try again")
            }
            finally {
                setVerifyLoading(false)
            }
        }
        else if (auth_type === 'google') {
            try {
                await saveUserDataGoogleLogin(dataToSend)
            }
            catch (error) {
                showMessage("error", error?.message || "Something went wrong. Please try again")
            }
            finally {
                setVerifyLoading(false)
            }
        }
    }


    //function to send otp to the user phone number through firebase
    const verifyOtp = async (otp) => {

        if (!otp || otp?.length < 6) {
            showMessage("error", "Please enter valid OTP")
            return;
        }
        await loginTheUser(otp)
    }

    const handleOtpChange = (otpNumber) => {
        setOtp(otpNumber)
        if (otpNumber?.length >= 6) {
            verifyOtp(otpNumber)
        }
    }

    return (
        <Box style={{
            minHeight: '180px',
        }}
            className="relative"
        >
            <Grid className=''>
                <div className='text-black'>
                    <h2 className='text-2xl font-semibold'>Welcome, {data?.first_name}</h2>
                    <h2 className='text-base'>Authenticate your account</h2>
                </div>

                <Grid item xs={12} className='mt-[10px] text-black'>
                    <div id="recaptcha-container" className='my-3'></div>
                    <p className="text-md mb-2 text-black" >Enter Two factor code from authenticator app</p>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <OtpInput
                                value={otp}
                                onChange={(otpNumber) => handleOtpChange(otpNumber)}
                                numInputs={6}
                                containerStyle={{ justifyContent: 'space-between' }}
                                inputStyle={{
                                    width: '100%',
                                    margin: '8px',
                                    padding: '10px',
                                    border: `2px solid ${borderColor}`,
                                    borderRadius: 4,
                                    ':hover': {
                                        borderColor: theme.palette.primary.main
                                    }
                                }}
                                focusStyle={{
                                    outline: 'none',
                                    border: `2px solid ${theme.palette.primary.main}`
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                disableElevation
                                fullWidth size="large"
                                type="submit"
                                variant="contained"
                                onClick={verifyOtp}
                                loading={verifyLoading}
                                disabled={verifyLoading}
                            >
                                Verify
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <div className='mt-3'>
                        {error ?
                            <div className={`${Styles.error_box} p-3`}>
                                {error}
                            </div>
                            :
                            success ?
                                <div className={`${Styles.success_box} p-3`}>
                                    {success}
                                </div>
                                :
                                <></>
                        }
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TwoFaForm;
