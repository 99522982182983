import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../../assets/images/logo.png"
import { IconBuilding, IconMail, IconPhone } from '@tabler/icons';
import { height, width } from '@mui/system';
import { Divider } from '@mui/material';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-10">
            <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 px-6">
                <div className='flex flex-col gap-3'>
                    <Link
                        className="font-semibold text-2xl cursor-pointer flex items-center"
                        to={'https://ringus.ai'}
                    >
                        <span className=" mr-1 ">
                            <h1 className="font-[700] flex items-center justify-center text-3xl">
                                <img src={logo} alt='' className='h-10' />&nbsp; Ring<span className="text-[#169CD9] bg-transparent">Us</span>
                            </h1>
                        </span>
                    </Link>
                    <p className='leading-5'>
                        Unleash the power of RingUs, an unparalleled AI Platform
                        where we revolutionize the way businesses handle customer interactions.
                    </p>
                </div>

                {/* Quick Links */}
                <div>
                    <h2 className="text-lg font-semibold mb-4">Quick Links</h2>
                    <ul className='leading-7'>
                        <li><a href="/" className="hover:underline">Home</a></li>
                        <li><a href="/about-us" className="hover:underline">About Us</a></li>
                        <li><a href="/product" className="hover:underline">Product</a></li>
                        <li><a href="/contact-us" className="hover:underline">Contact Us</a></li>
                    </ul>
                </div>

                {/* Policy */}
                <div>
                    <h2 className="text-lg font-semibold mb-4">Policy</h2>
                    <ul className='leading-7'>
                        <li><a href="/privacy-policy" className="hover:underline">Privacy Policy</a></li>
                        <li><a href="/terms-and-conditions" className="hover:underline">Terms & Conditions</a></li>
                        <li><a href="/refund-policy" className="hover:underline">Cancellations & Refunds Policy</a></li>
                    </ul>
                </div>

                {/* Contact Us */}
                <div>
                    <h2 className="text-lg font-semibold mb-4">Contact Us</h2>
                    <div className='leading-2'>
                        <a href='https://ringus.ai/contact-us'>Contact Us</a>
                        <address className="not-italic mt-4 leading-6">
                            <p className='flex gap-2'>
                                <IconBuilding style={{height:'50px', width:'50px'}}/>
                                <span> Unit No. 505, KLJ Tower North, Netaji Subhash Place, Pitampura, Delhi - 110034 </span>
                            </p>
                            <p className='flex gap-2'>
                                <IconMail />
                                <a href="mailto:admin@techlane.ai" className="text-blue-600 hover:underline">
                                    admin@techlane.ai
                                </a>
                            </p>
                            <p className='flex gap-2 mt-[7px]'>
                                <IconPhone />
                                +91 9773665117
                            </p>
                            <br />
                        </address>
                    </div>
                </div>
            </div>
            <Divider style={{borderColor: '#44474c'}}/>
            <div className="text-center text-gray-400 mt-5">
                &copy; {new Date().getFullYear()} Techlane AI Private Limited. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
