import { LoadingButton } from "@mui/lab"
import { Button, Divider, FormControlLabel, FormHelperText, Grid, Paper, RadioGroup, TextField, Typography, Radio } from "@mui/material"
import { IconLock } from "@tabler/icons"
import { Link } from "react-router-dom"
import currencySymbolMap from 'currency-symbol-map';

const CheckoutPageForm = ({
    formatTime, timeLeft, values, error, handleChange, paymentType, setPaymentType,
    handleCheckout, proceedPaymentLoading, handleApplyCoupon, couponLoading, isCouponApplied,
    productDetails, handleRemoveCoupon
}) => {

    return (
        <Paper className="w-full max-w-5xl p-6 rounded-[5px]" elevation={3}>
            <div className="flex justify-end mb-2">
                <Typography variant="h4">
                    Time Left: {formatTime(timeLeft)}
                </Typography>
            </div>
            <Grid container spacing={4}>
                {/* User Info Form */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h3" className="mb-4">
                        User Information
                    </Typography>
                    <form className="space-y-4">
                        <p className="text-md" >Name</p>
                        <TextField
                            fullWidth
                            placeholder="Your name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            style={{ marginTop: '2px' }}
                        />

                        <p className="text-md" >Email</p>
                        <TextField
                            fullWidth
                            placeholder="Your Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            style={{ marginTop: '2px' }}

                        />

                        <h1 className="text-md mb-2" >
                            Phone Number
                            <span className='text-red-400'>*</span>
                        </h1>
                        <TextField
                            fullWidth
                            placeholder="+918783984674"
                            name="phone_number"
                            value={values.phone_number}
                            onChange={handleChange}
                            style={{ marginTop: '2px' }}

                        />
                        {error.phone_number && (
                            <FormHelperText error style={{ marginTop: '1px' }}>
                                Valid phone number is required
                            </FormHelperText>
                        )}

                        <p className="text-md" >Address</p>
                        <TextField
                            fullWidth
                            placeholder="Your Address"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            style={{ marginTop: '2px' }}

                        />

                        {productDetails?.type === 'SUBSCRIPTION' &&
                            <>
                                <h1 className="text-md " >
                                    Payment type
                                    <span className='text-red-400'>*</span>
                                </h1>
                                <RadioGroup
                                    aria-label="payment type"
                                    name="paymentType"
                                    value={paymentType}
                                    onChange={(e) => setPaymentType(e.target.value)}
                                    style={{ display: 'flex', flexDirection: 'row', marginTop: '0px' }}
                                >

                                    <FormControlLabel value="oneTimePayment" control={<Radio />} label="One Time" />
                                    <FormControlLabel value="recurringPayment" control={<Radio />} label="Recurring" disabled />
                                </RadioGroup>
                            </>
                        }

                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleCheckout}
                            loading={proceedPaymentLoading}
                        >
                            Proceed to Payment
                        </LoadingButton>

                        <Divider />

                        <div className="mt-4 flex justify-center gap-3 text-sm font-semibold">
                            <Link
                                to="https://ringus.ai/privacy-policy"
                                target='_blank'
                                color="primary"
                                className='underline'
                            >
                                Refund Policy
                            </Link>
                            <Link
                                to="https://ringus.ai/privacy-policy"
                                target='_blank'
                                color="primary"
                                className='underline'
                            >
                                Privacy Policy
                            </Link>
                        </div>
                    </form>
                </Grid>

                {/* Product Details and Amount */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h3" className="mb-4">
                        Order Summary
                    </Typography>
                    <div className="space-y-4">
                        <div className="mt-5 flex flex-col gap-2">
                            <div className='flex justify-between items-center text-md'>
                                <p>Product name</p>
                                <Typography variant="h5">{productDetails?.product_name}</Typography>
                            </div>
                            <div className='flex justify-between items-center'>
                                <p>Product description</p>
                                <Typography variant="h5">{productDetails?.product}</Typography>
                            </div>
                            <div className='flex justify-between items-center'>
                                <p>Price</p>
                                <Typography variant="h5">
                                    {`${currencySymbolMap(productDetails?.currency)} ${productDetails?.amount}`}
                                </Typography>
                            </div>
                        </div>
                        <Divider className='mt-1' />

                        <div className="flex items-center space-x-2 mt-4">
                            <TextField
                                fullWidth
                                placeholder="Coupon code"
                                name="coupon_code"
                                value={values.coupon_code}
                                onChange={handleChange}
                                disabled={isCouponApplied}
                            />
                            {!isCouponApplied ?
                                <LoadingButton
                                    variant="contained"
                                    className="outlined-button"
                                    color="primary"
                                    onClick={handleApplyCoupon}
                                    loading={couponLoading}
                                    style={{ padding: '10px 20px' }}
                                >
                                    Apply
                                </LoadingButton>
                                :
                                <Button
                                    variant="outlined"
                                    className="outlined-button"
                                    color="primary"
                                    onClick={handleRemoveCoupon}
                                    style={{ padding: '10px 20px' }}
                                >
                                    Remove
                                </Button>
                            }
                        </div>
                        <div className="mt-5 flex flex-col gap-2">
                            <div className='flex justify-between items-center text-md'>
                                <p>Subtotal</p>
                                <Typography variant="h5">
                                    {`${currencySymbolMap(productDetails?.currency)} ${productDetails?.amount}`}
                                </Typography>
                            </div>
                            <div className='flex justify-between items-center'>
                                <p>Discount</p>
                                <Typography variant="h5" className="text-red-600">
                                    {'-'}
                                    <span className="text-red-600 line-through">
                                        {`${currencySymbolMap(productDetails?.currency)} ${productDetails?.discount}`}
                                    </span>
                                </Typography>
                            </div>
                            <div className='flex justify-between items-center'>
                                <p>Estimated Taxes(gst 18%)</p>
                                <Typography variant="h5">
                                    {`${currencySymbolMap(productDetails?.currency)} ${productDetails?.gstAmount}`}
                                </Typography>
                            </div>

                            <Divider className='mt-1' />
                            <div className='flex justify-between items-center mt-2 text-lg'>
                                <p>Total</p>
                                <p className='font-semibold'>
                                    {`${currencySymbolMap(productDetails?.currency)}${' '}
                                    ${isCouponApplied ?
                                            parseFloat((productDetails?.totalAmount + productDetails?.gstAmount).toFixed(2))
                                            : parseFloat((productDetails?.amount + productDetails?.gstAmount).toFixed(2))
                                        }`
                                    }
                                </p>
                            </div>
                        </div>
                        <div className='flex gap-1'>
                            <IconLock />
                            <div>
                                <p className='text-base'>Secure Payment</p>
                                <p className='text-xs'>Checkout with confidence</p>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default CheckoutPageForm
