import React, { useEffect, useRef, useState } from 'react'
// import "./Navbar.css";
import { Link as ReactLink } from 'react-router-dom'
import logo from "../../../assets/images/logo.png"
import { Link } from "react-scroll";
import { IconMenu2 } from '@tabler/icons';
import { IconButton } from '@mui/material';

const Navbar = ({ position = '' }) => {

  let Links = [
    {
      name: 'Home',
      link: 'Home',
    },
    {
      name: 'About',
      link: 'research',
    },
    {
      name: 'Product',
      link: 'about',
    },
    {
      name: 'Get Started',
      link: 'started',
    },
  ]

  let [open, setOpen] = useState(false)

  const [openDropdown, setOpenDropdown] = useState(false)

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <div
        className={`w-full sm:top-0 z-50 block xs:flex items-center justify-center fixed backdrop-filter backdrop-blur-lg left-0`}
        style={{ position: position }}
      >
        <div className="xs:container">
          <div className="flex items-center justify-between backdrop-filter backdrop-blur-lg py-4 xl:px-32 px-4">

            <ReactLink
              className="font-semibold text-2xl cursor-pointer flex items-center text-gray-800"
              to={'https://ringus.ai'}
            >
              <span className=" mr-1 ">
                <h1 className="font-[700] flex items-center justify-center text-3xl">
                  <img src={logo} alt='' className='h-10' />&nbsp; Ring<span className="text-[#169CD9] bg-transparent">Us</span>
                </h1>
              </span>
            </ReactLink>

            <div
              className="text-3xl absolute right-8 top-5 cursor-pointer md:hidden"
              onClick={() => setOpen(!open)}
            >
              <IconMenu2 />
            </div>

            <ul
              ref={dropdownRef}
              className={`lg:flex text-gray-800 lg:items-center lg:pb-0 absolute lg:static lg:z-auto left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-300 ease-in-out ${open ? 'right-20' : 'top-[-490px]'} ${open && 'mt-[400px]'} bg-white lg:mt-0 lg:bg-inherit`}
            >
              {Links.map((link) => {
                return (
                  <li
                    key={link.name}
                    className="lg:ml-8 text-[16px] lg:text-[16px] cursor-pointer"
                  >
                    <Link
                      to={link.link}
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                      onClick={() => setOpen(!open)}
                      className=" font-[500] lg:my-0 my-8 hover:text-[#169CD9] flex duration-500"
                    >
                      {link.name}{' '}
                    </Link>

                  </li>
                )
              })}
              <li
                className="lg:ml-8 text-[16px] lg:text-[16px] cursor-pointer"
              >
                <ReactLink
                  className=" font-[500] lg:my-0 my-8 hover:text-[#169CD9] flex duration-500"
                  to={'https://ringus.ai/contact-us'}
                >
                  Contact Us
                </ReactLink>
              </li>

              <div className="flex lg:hidden text-gray-800 flex-row gap-6 relative left-0 lg:left-[0%] my-5">
                <a
                  href="https://ringus.ai/login"
                  className="text-16 font-bold"
                >
                  <button className="mt-auto rounded-md py-2 px-5 text-18 text-center border-gray-800 border-2">
                    Login
                  </button>
                </a>
                <a
                  href="https://ringus.ai/sign-up"
                  className="text-16 font-bold"
                >
                  <button className="mt-auto rounded-md py-2 px-5 text-18 text-center text-white bg-[#169CD9]">
                    Signup
                  </button>
                </a>
              </div>

            </ul>

            <div className="md:flex hidden text-gray-800 flex-row gap-6 relative left-0 md:left-[0%] max-md:mt-20">
              <a
                href="https://ringus.ai/login"
                className="text-16 font-bold"
              >
                <button className="mt-auto rounded-md py-2 px-5 text-18 text-center border-gray-800 border-2">
                  Login
                </button>
              </a>
              <a
                href="https://ringus.ai/sign-up"
                className="text-16 font-bold"
              >
                <button className="mt-auto rounded-md py-2 px-5 text-18 text-center text-white bg-[#169CD9]">
                  Signup
                </button>
              </a>
            </div>

          </div>
        </div>
      </div>
      <hr />
    </>
  )
}

export default Navbar
