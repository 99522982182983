import React from 'react';

const TermsAndConditionsPage = () => {
  return (
    <>
      <div className="max-w-4xl mx-auto p-8 mt-20">
        <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <h3 className="text-lg font-semibold mb-2 ml-4">1.1 Acceptance of Terms</h3>
        <p className="mb-4 ml-8">
          By engaging with Ringus.AI, a product of Techlane AI Private Limited (referred to as the “Company”), clients (referred to as the “Client”) acknowledge and accept the terms and conditions set forth herein. These terms constitute a legally binding agreement.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">1.2 Changes to Terms</h3>
        <p className="mb-4 ml-8">
          The Company reserves the right to modify these terms at its discretion. Any changes will be communicated to the Client in writing.
        </p>

        <h2 className="text-2xl font-semibold mb-4">2. Definitions</h2>
        <h3 className="text-lg font-semibold mb-2 ml-4">2.1 “Company”</h3>
        <p className="mb-4 ml-8">
          Refers to TechLane AI Private Limited, a software consultancy and development company.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">2.2 “Client”</h3>
        <p className="mb-4 ml-8">
          Refers to the party or parties contracting the services of the Company.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">2.3 “Services”</h3>
        <p className="mb-4 ml-8">
          Refers to software consultancy and development services provided by the Company as described in the Statement of Work (SOW).
        </p>

        <h2 className="text-2xl font-semibold mb-4">3. Scope of Services</h2>
        <h3 className="text-lg font-semibold mb-2 ml-4">3.1 Description of Services</h3>
        <p className="mb-4 ml-8">
          The Company agrees to provide software consultancy and development services as detailed in the mutually agreed-upon Statement of Work (SOW).
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">3.2 Project Timeline and Milestones</h3>
        <p className="mb-4 ml-8">
          The SOW will outline project timelines, milestones, and deliverables. Any delays or changes to the project scope must be agreed upon by both parties in writing.
        </p>

        <h2 className="text-2xl font-semibold mb-4">4. Payment Terms</h2>
        <h3 className="text-lg font-semibold mb-2 ml-4">4.1 Pricing and Payment Structure</h3>
        <p className="mb-4 ml-8">
          Pricing details and payment structure are specified in the SOW. Payment terms, including invoicing, currency, and payment methods, are outlined in the SOW and must be adhered to.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">4.2 Payment Schedule</h3>
        <p className="mb-4 ml-8">
          The Client shall adhere to the payment schedule as specified in the SOW. Late payments may result in penalties and the suspension of services.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">4.3 Late Payment</h3>
        <p className="mb-4 ml-8">
          In the event of late payment, the Company reserves the right to charge interest on the overdue amount at a rate specified in the SOW.
        </p>

        <h2 className="text-2xl font-semibold mb-4">5. Intellectual Property</h2>
        <h3 className="text-lg font-semibold mb-2 ml-4">5.1 Ownership of Work</h3>
        <p className="mb-4 ml-8">
          The Company retains ownership of all intellectual property and deliverables until full payment is received. Upon full payment, the Client will be granted a license as detailed in the SOW.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">5.2 License to the Client</h3>
        <p className="mb-4 ml-8">
          The Client will be granted a non-exclusive, non-transferable license to use the deliverables solely for their intended purpose.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">5.3 Confidentiality</h3>
        <p className="mb-4 ml-8">
          Both parties agree to maintain the confidentiality of all project-related information, trade secrets, and proprietary data.
        </p>

        <h2 className="text-2xl font-semibold mb-4">6. Warranties and Liabilities</h2>
        <h3 className="text-lg font-semibold mb-2 ml-4">6.1 Warranty of Services</h3>
        <p className="mb-4 ml-8">
          The Company warrants that services will be performed with reasonable care and skill. The Company’s liability for any breach is limited as outlined in the SOW.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">6.2 Limitation of Liability</h3>
        <p className="mb-4 ml-8">
          The Company’s liability for any loss or damage arising from the services provided is limited to the amount paid by the Client as detailed in the SOW.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">6.3 Indemnification</h3>
        <p className="mb-4 ml-8">
          The Client agrees to indemnify and hold the Company harmless from any claims, liabilities, or expenses arising from the Client’s use of the deliverables.
        </p>

        <h2 className="text-2xl font-semibold mb-4">7. Termination</h2>
        <h3 className="text-lg font-semibold mb-2 ml-4">7.1 Termination by the Client</h3>
        <p className="mb-4 ml-8">
          The Client may terminate the agreement as specified in the SOW. Termination may result in additional fees.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">7.2 Termination by the Company</h3>
        <p className="mb-4 ml-8">
          The Company may terminate the agreement due to Client breach or non-payment. Termination conditions are specified in the SOW.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">7.3 Effect of Termination</h3>
        <p className="mb-4 ml-8">
          Upon termination, the Client must cease using any deliverables, and ownership will revert to the Company as outlined in the SOW.
        </p>

        <h2 className="text-2xl font-semibold mb-4">8. Data Protection and Privacy</h2>
        <h3 className="text-lg font-semibold mb-2 ml-4">8.1 Data Handling and Security</h3>
        <p className="mb-4 ml-8">
          The Company will handle and protect data in accordance with relevant data protection laws and the Company’s privacy policy.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">8.2 Privacy Policy</h3>
        <p className="mb-4 ml-8">
          The Company’s privacy policy outlines how personal data is collected, used, and protected. The Client should review this policy.
        </p>

        <h2 className="text-2xl font-semibold mb-4">9. Dispute Resolution</h2>
        <h3 className="text-lg font-semibold mb-2 ml-4">9.1 Mediation</h3>
        <p className="mb-4 ml-8">
          In the event of a dispute, both parties agree to engage in good faith mediation to resolve issues.
        </p>
        <h3 className="text-lg font-semibold mb-2 ml-4">9.2 Arbitration</h3>
        <p className="mb-4 ml-8">
          If mediation fails, disputes shall be settled through binding arbitration in accordance with New Delhi jurisdiction, unless otherwise agreed upon.
        </p>

        <h2 className="text-2xl font-semibold mb-4">10. Governing Law</h2>
        <p className="mb-4">
          This agreement shall be governed by and construed in accordance with the laws of New Delhi.
        </p>

        <h2 className="text-2xl font-semibold mb-4">11. Entire Agreement</h2>
        <p className="mb-4">
          This agreement, including any attached SOW, constitutes the entire understanding between the parties and supersedes all prior agreements, representations, or understandings.
        </p>

        <h2 className="text-2xl font-semibold mb-4">12. Severability</h2>
        <p className="mb-4">
          If any provision of these terms is found to be unenforceable or invalid, it shall not affect the validity of the remaining provisions.
        </p>

        <h2 className="text-2xl font-semibold mb-4">13. No Waiver</h2>
        <p className="mb-4">
          Failure to enforce any provision of these terms at any time shall not constitute a waiver of that provision.
        </p>

        <h2 className="text-2xl font-semibold mb-4">14. Assignment</h2>
        <p className="mb-4">
          Neither party may assign or transfer its rights or obligations under this agreement without the written consent of the other party.
        </p>

        <h2 className="text-2xl font-semibold mb-4">15. Miscellaneous</h2>
        <p className="mb-4">
          This document represents the binding agreement between the Company and the Client. By engaging with the Company, the Client agrees to these terms and conditions.
        </p>

        <section className="mb-6 mt-6">
          <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
          <p>
            If you have any questions about these Terms and Conditions, please contact us at:
          </p>
          <address className="not-italic mt-4 leading-[1.3rem]">
            <span className='font-semibold'>Techlane AI Private Limited</span><br />
            Unit No. 505, KLJ Tower North, Netaji Subhash Place, Pitampura, Delhi - 110034 <br />
            <a href="mailto:admin@techlane.ai" className="text-blue-600 hover:underline">
              admin@techlane.ai
            </a>
            <br />
            +91 9773665117
          </address>
        </section>
      </div>

    </>
  );
};

export default TermsAndConditionsPage;


{/* <div className="max-w-4xl mx-auto p-6 bg-white mt-[7rem]">
        <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>

        <section className="mb-6">
          <div style={{ height: '100vh', width: '100%' }}>
            <iframe
              src={'https://ringus.s3.ap-south-1.amazonaws.com/ringus_tnc.pdf'}
              width="100%"
              height="100%"
              style={{
                borderBottom: '10px solid',
                borderLeft: '10px solid',
                borderRight: '10px solid',
                borderRadius: '5px'
              }}
            />
          </div>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
          <p>
            If you have any questions about these Terms and Conditions, please contact us at:
          </p>
          <address className="not-italic mt-4 leading-[1.3rem]">
            <span className='font-semibold'>Techlane AI Private Limited</span><br />
            Unit No. 505, KLJ Tower North, Netaji Subhash Place, Pitampura, Delhi - 110034 <br />
            <a href="mailto:admin@techlane.ai" className="text-blue-600 hover:underline">
              admin@techlane.ai
            </a>
            <br />
            +91 9773665117
          </address>
        </section>
      </div> */}