import React from "react";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import research from "../../../assets/images/researchImage.png";
import { IconArrowRight } from "@tabler/icons";

const Research = ({ padding = '0px' }) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 120 }, // Change x: 100 to x: -100
      }}
      transition={{ duration: 0.7 }}

      className="bg-[--main-color]"
      id="research"
      style={{ paddingTop: padding, paddingBottom: padding }}
    >
      <div className="flex items-center lg:flex-row flex-col justify-between px-10">

        <div className=" shadow-xl max-md:hidden md:w-[100%] h-full">
          <img loading="lazy" src={research} alt="" className="h-full w-full" />
        </div>


        <div className="lg:ml-32 flex flex-col justify-center w-[100%]">
          <div className="flex  flex-col md:items-start items-center justify-center">

            <h1 className="py-4 text-3xl md:w-[90%] text-center md:text-left md:text-5xl font-[600]">
              <span className="text-[#169CD9]">
                Powerful Dashboard <br />
              </span>
              At Your Fingertips
            </h1>
          </div>

          <div className=" md:ml-24 shadow-xl md:hidden max-md:mx-auto w-[100%] my-3">
            <img
              loading="lazy"
              src={research}
              alt=""
              className="md:h-[400px]"
            />

          </div>
          <p
            className="md:px-0 text-[15px] md:text-[18px] text-justify font-[400] md:my-4 my-2 w-[100%]"
            style={{ lineHeight: '30px' }}
          >
            Experience the smooth and flawless dashboard of RingUs, where managing your business interactions is effortless. Easily buy phone numbers, create agents, and assign them to specific numbers with just a few clicks. Your data's privacy is our top priority; we ensure all information is securely stored and protected, so you can focus on growing your business with peace of mind.
          </p>
          <div className="flex md:items-start items-center md:justify-start justify-center  mt-8">
            <Link to="https://ringus.ai/login">
              <button className=" rounded-[56px] flex items-center text-white bg-[#169CD9] px-10 py-2 text-center">
                Get Started
                <IconArrowRight
                  className="bg-transparent ml-2 text-center"
                />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Research;
