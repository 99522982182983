import React, { useEffect, useRef, useState } from 'react';

// export const AudioVisualizer = ({ audioData }) => {
//     const canvasRef = useRef(null);

//     useEffect(() => {
//         const canvas = canvasRef.current;
//         const context = canvas.getContext('2d');
//         canvas.width = window.innerWidth;
//         canvas.height = 200;

//         const renderFrame = () => {
//             requestAnimationFrame(renderFrame);

//             context.clearRect(0, 0, canvas.width, canvas.height);
//             context.fillStyle = 'rgba(255, 255, 255, 1)';
//             context.fillRect(0, 0, canvas.width, canvas.height);

//             const barWidth = (canvas.width / audioData.length) * 1.2;
//             let barHeight;
//             let x = 0;

//             audioData.forEach(amplitude => {
//                 barHeight = amplitude * canvas.height;
//                 context.fillStyle = 'rgb(30, 144, 255)'; // DodgerBlue color
//                 context.fillRect(x, canvas.height - barHeight, barWidth, barHeight);

//                 x += barWidth + 1;
//             });
//         };

//         renderFrame();
//     }, [audioData]); // Re-render when audioData changes

//     return (
//         <canvas ref={canvasRef} className="w-full h-48 " />
//     );
// };


export const AudioVisualizer = ({ audioData }) => {
    return (
        <div className="w-full h-10 flex items-center justify-center space-x-1">
            {audioData?.map((value, i) => (
                <div
                    key={i}
                    className="w-[4px] bg-primary rounded-full"
                    style={{
                        transition: 'ease-in-out 30',
                        height: `${value * 400}%`,
                    }}
                />
            ))}
        </div>
    )
}



// export const AudioVisualizer = ({ audioData }) => {
//     const [scrollPosition, setScrollPosition] = useState(0);
//     const [combinedData, setCombinedData] = useState([]);

//     // Combine and update data
//     useEffect(() => {
//         setCombinedData([...audioData, ...audioData, ...audioData]); // Triple the data for smooth scrolling
//     }, [audioData]);

//     // Scrolling animation
//     useEffect(() => {
//         const scrollInterval = setInterval(() => {
//             setScrollPosition((prev) => {
//                 if (prev >= audioData.length * 3) return 0; // Reset when reaching end
//                 return prev + 1;
//             });
//         }, 50); // Adjust speed by changing interval

//         return () => clearInterval(scrollInterval);
//     }, [audioData.length]);

//     return (
//         <div className="w-full h-10 overflow-hidden">
//             <div
//                 className="flex items-center space-x-1 transition-transform duration-300 h-60"
//                 style={{
//                     transform: `translateX(-${scrollPosition * 3}px)`, // 3px = 2px bar width + 1px space
//                 }}
//             >
//                 {combinedData.map((value, i) => (
//                     <div
//                         key={i}
//                         className="min-w-[2px] bg-primary transition-all duration-75"
//                         style={{
//                             height: `${value * 400}%`,
//                         }}
//                     />
//                 ))}
//             </div>
//         </div>
//     );
// };
