/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';


const initialState = {
    error: null,
    phoneNumbers: null,
    singleTakenNumber: null,
    totalPages: null,
    totalCount: null,
    loading: false,
    assignAgentLoading: false,
    releaseNumberLoading: false,
};

const slice = createSlice({
    name: 'phoneNumber',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isAssignAgentLoading(state, action) {
            state.assignAgentLoading = action.payload;
        },

        isReleaseNumberLoading(state, action) {
            state.releaseNumberLoading = action.payload;
        },

        setPhoneNumberDataSuccess(state, action) {
            state.phoneNumbers = action.payload.data;
            state.totalPages = action.payload.totalPages;
            state.totalCount = action.payload.totalCount;
        },

        setSingleTakenPhoneNumberDataSuccess(state, action) {
            state.singleTakenNumber = action.payload;
        },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllPhoneNumbers({ status, country, page, limit }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/numbers/all?status=${status}&country=${country}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setPhoneNumberDataSuccess(response.data));

        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}
export function getAllPhoneNumbersOfUser({ page, limit, phone_number }) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/numbers/user/all?phone_number=${phone_number}&page=${page}&limit=${limit}`);
            dispatch(slice.actions.setPhoneNumberDataSuccess(response.data));

        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function getSingleTakenPhoneNumber(id) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/numbers/user/${id}`);
            dispatch(slice.actions.setSingleTakenPhoneNumberDataSuccess(response.data?.data));

        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function assignPhoneNumberToAgent(data) {
    return async () => {
        try {
            dispatch(slice.actions.isAssignAgentLoading(true));
            const response = await axios.put(`/numbers/assign-agent`, data);
            handleSuccess(response, "Phone number assigned successfully", dispatch);

            return response.data;
        }
        catch (err) {
            // throw err
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isAssignAgentLoading(false));
        }
    }
}

export function validatePhoneNumberPurchase(phone_number_id) {
    return async () => {
        try {
            const response = await axios.get(`/numbers/validate-purchase?phone_number_id=${phone_number_id}`);
            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
    }
}

export function buyNumber(data) {
    return async () => {
        try {
            const response = await axios.post(`/numbers/buy`, data);
            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
    }
}

export function releaseNumber(id) {
    return async () => {
        try {
            dispatch(slice.actions.isReleaseNumberLoading(true));
            const response = await axios.put(`/numbers/release/${id}`);

            handleSuccess(response, "Phone number released successfully", dispatch);
            // return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
            throw err
        }
        finally {
            dispatch(slice.actions.isReleaseNumberLoading(false));
        }
    }
}
