// material-ui
import { CircularProgress } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { styled, keyframes } from '@mui/material/styles';
import Lottie from "lottie-react";
import loader_json from '../assets/images/loader-json.json'

const fillAnimation = keyframes`
  0% {
    width: 0;
  }
  20% {
    width: 20%;
  }
  40% {
    width: 40%;
  }
  60% {
    width: 60%;
  }
  80% {
    width: 80%;
  }
  100% {
    width: 100%;
  }
`;

// styles
const LoaderWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1301,
  width: '100%',
  height: '100%',
});

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '5px',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#169CD9',
    animation: `${fillAnimation} 2s ease-in-out forwards`,
  }
}));

const LoadingItem = styled('div')({
  fontSize: '16px',       // Adjust font size of loading text
  fontWeight: 'semibold',     // Make it bold for emphasis
  color: '#333',          // Text color
  marginBottom: '20px',   // Space between the loading text and the progress bar
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)', // Center the loading text
});


// ==============================|| LOADER ||============================== //

const Loader = () => (
  <div className='h-screen'>

    <LoadingItem>
      <div className='flex flex-col items-center gap-2'>
        <Lottie animationData={loader_json} loop={true} style={{ height: '60px', width: '60px' }} />
        <p>Loading...</p>
      </div>
    </LoadingItem>

    <LoaderWrapper>
      <CustomLinearProgress />
    </LoaderWrapper>
  </div>
);

export default Loader;

