/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    sessionToken: null,
    sessionData: null,
    sessionExpiration: null,
    loading: false
};

const slice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        setSessionDataSuccess(state, action) {
            state.sessionData = action.payload?.data;
            state.sessionExpiration = action.payload?.expires;
        },

        setSessionTokenSuccess(state, action) {
            state.sessionToken = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function createSessionToken(data) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.post(`/session/create`, data);
            dispatch(slice.actions.setSessionTokenSuccess(response.data?.session_id))
            dispatch(slice.actions.isLoading(false))

            return response.data?.session_id;
        }
        catch (err) {
            handleError(err, dispatch);

        }
    }
}

export function getSessionData(session_id) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/session/get-session-data?session_id=${session_id}`);
            dispatch(slice.actions.setSessionDataSuccess(response.data?.sessionData))
            dispatch(slice.actions.isLoading(false))

            return response.data?.sessionData?.data
        }
        catch (err) {
            handleError(err, dispatch);

        }
    }
}

export function clearSession(data) {
    //id -> subscriptionPlan id or plan id
    return async () => {
        try {
            const response = await axios.post(`/session/clear-session`, data);
            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
    }
}
