import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useSelector } from '../../store';
import NoPermissionPage from '../../views/pages/others/NoPermissionPage';

//Read guard
export const ApiKeysReadGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)
    const isReadAllowed = member_permission?.api_key?.view_keys

    return isReadAllowed ? children : <NoPermissionPage />
};

ApiKeysReadGuard.propTypes = {
    children: PropTypes.node
};

//Write guard
export const CreateApiKeyGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isWriteAllowed = member_permission?.api_key?.create_key

    return isWriteAllowed ? children : <NoPermissionPage />
};

CreateApiKeyGuard.propTypes = {
    children: PropTypes.node
};
