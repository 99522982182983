/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    open: false,
    title: "Title",
    element: null,
    loading: false,
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const detailsDrawer = createSlice({
    name: 'detailsDrawer',
    initialState,
    reducers: {
        openDetailsDrawer(state, action) {
            const { open, title, element, isButtonClicked } = action.payload;
            state.open = open || initialState.open;
            state.title = title || initialState.title;
            state.element = element || initialState.element;
        },
        showLoading(state) {
            state.loading = !state.loading;
        },
        closeDetailsDrawer(state) {
            state.open = false;
        }
    }
});

export default detailsDrawer.reducer;

export const { openDetailsDrawer, closeDetailsDrawer, showLoading } = detailsDrawer.actions;
