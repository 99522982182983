import { act, memo, useEffect, useMemo, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Drawer, FormControl, MenuItem, Modal, Popover, Select, Stack, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from '../MenuList';
import LogoSection from '../LogoSection';
import MiniDrawerStyled from './MiniDrawerStyled';

import LAYOUT_CONST from '../../../constant';
import useConfig from '../../../hooks/useConfig';
import { drawerWidth } from '../../../store/constant';

import { useDispatch, useSelector } from '../../../store';
import { openDrawer } from '../../../store/slices/menu';
import { useNavigate } from 'react-router-dom';
import ProfileSection from '../Header/ProfileSection'
import Organization from '../Header/ProfileSection/organizationTwo';
// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const { loading: orgs_loading, organizations } = useSelector(state => state.organization)

    const { active_org, data: userData } = useSelector(state => state.account)

    const level = 1

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const { layout, drawerType } = useConfig();

    const drawerSX = {
        paddingLeft: drawerOpen ? '16px' : 0,
        paddingRight: drawerOpen ? '16px' : 0,
        marginTop: drawerOpen ? '-15px' : '0px'
    };

    const logo = useMemo(
        () => (
            <>
                <div className={`${drawerOpen ? 'px-8' : 'pl-4'} py-6 flex items-center gap-3`}>
                    <LogoSection />
                    {(drawerOpen || (!drawerOpen && level !== 1)) && (
                        <h1 className='text-2xl font-bold'>
                            Ring
                            <span className='text-[#169CD9]'>Us</span>
                        </h1>
                    )}
                </div>
            </>
        ),
        [matchUpMd, drawerOpen, drawerType]
    );

    const organization = useMemo(
        () => (
            <>
                <div
                    className={`${drawerOpen ? 'px-8' : 'px-3'}`}
                >
                    <Organization
                        organizations={organizations}
                        active_org={active_org}
                        collapsed={!drawerOpen}
                    />
                </div>
            </>
        ),
        [matchUpMd, drawerOpen, drawerType, active_org]
    );

    const drawerContent = (
        <>
            <MenuList />
        </>
    );

    const drawer = useMemo(
        () => (
            <>
                {matchDownMd ? (
                    <PerfectScrollbar
                        component="div"
                        style={{
                            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                            ...drawerSX
                        }}
                    >
                        {drawerContent}
                    </PerfectScrollbar>

                ) : (
                    <PerfectScrollbar
                        component="div"
                        style={{
                            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                            ...drawerSX
                        }}
                    >
                        {drawerContent}
                    </PerfectScrollbar>
                )}
            </>
        ),
        [matchUpMd, drawerOpen, drawerType]
    );

    useEffect(() => {
        const railX = document.querySelectorAll('.ps__rail-x');
        if (railX) {
            railX[1]?.style.setProperty('display', 'none', 'important');
        }
        const railY = document.querySelectorAll('.ps__rail-y');
        if (railY) {
            railY[1]?.style.setProperty('display', 'none', 'important');
        }
    }, [matchUpMd, drawerOpen, drawerType]);

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            {matchDownMd || (drawerType === LAYOUT_CONST.MINI_DRAWER && drawerOpen) ? (
                <Drawer
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => dispatch(openDrawer(!drawerOpen))}
                    sx={{
                        '& .MuiDrawer-paper': {
                            mt: matchDownMd ? 0 : 11,
                            zIndex: 1099,
                            width: drawerWidth,
                            background: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRight: 'none'
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {matchDownMd && logo}
                    <div className='relative'>
                        {organization}
                    </div>
                    {drawer}
                    <div className='relative'>
                        <ProfileSection
                            drawerOpen={drawerOpen}
                            level={level}
                        />
                    </div>
                </Drawer>
            ) : (
                <MiniDrawerStyled variant="permanent" open={drawerOpen}>
                    {logo}
                    <div className='relative'>
                        {organization}
                    </div>
                    {drawer}
                    <div className='relative'>
                        <ProfileSection
                            drawerOpen={drawerOpen}
                            level={level}
                        />
                    </div>

                </MiniDrawerStyled>
            )}
        </Box>
    );
};

export default memo(Sidebar);
