import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useSelector } from '../../store';
import NoPermissionPage from '../../views/pages/others/NoPermissionPage';

//Read guard
export const CallHistoryReadGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isReadAllowed = member_permission?.call_history?.view_history

    return isReadAllowed ? children : <NoPermissionPage />
};

CallHistoryReadGuard.propTypes = {
    children: PropTypes.node
};

