/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from './snackbar';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    chatBots: null,
    singleChatBot: null,
    loading: false,
    singleChatBotLoading: false,
    updateChatBotLoading: false,
};

const slice = createSlice({
    name: 'chatBot',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isUpdateChatBotLoading(state, action) {
            state.updateChatBotLoading = action.payload;
        },

        isSingleChatBotLoading(state, action) {
            state.singleChatBotLoading = action.payload;
        },

        setChatBotDataSuccess(state, action) {
            state.chatBots = action.payload;
        },

        setSingleChatBotDataSuccess(state, action) {
            state.singleChatBot = action.payload;
        },

        createChatBotDataSuccess(state, action) {
            state.chatBots = [...state.chatBots, action.payload];
        },

        updateChatBotDataSuccess(state, action) {
            const index = state.chatBots.findIndex(chatBot => chatBot._id === action.payload.id);
            const chatBotData = state.chatBots[index];
            const newData = { ...chatBotData, ...action.payload.data };
            state.chatBots[index] = newData
        },

        deleteChatBotDataSuccess(state, action) {
            const newChatBots = state.chatBots.filter(chatBot => chatBot._id !== action.payload.chatBot_id);
            state.chatBots = newChatBots;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Utility function to handle errors
const handleError = (error, dispatch) => {
    console.log(error);
    const errorMessage = error.response?.data?.message || error?.message || 'An unexpected error occurred';
    dispatch(slice.actions.hasError(errorMessage));
    dispatch(openSnackbar({
        open: true,
        message: errorMessage,
        variant: 'alert',
        alert: { color: "error" },
        close: true
    }));
};

// Utility function to handle success
const handleSuccess = (response, message, dispatch) => {
    const successMessage = response?.data?.message || message;
    console.error('successMessage:', successMessage);

    dispatch(openSnackbar({
        open: true,
        message: successMessage,
        variant: 'alert',
        alert: { color: "success" },
        close: true
    }));
};

export function getAllChatBots() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/chat-bot/all`);
            dispatch(slice.actions.setChatBotDataSuccess(response.data?.data));

        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false));
        }
    }
}

export function getSingleChatBot(id) {
    //id -> mongoDB _id
    return async () => {
        try {
            dispatch(slice.actions.isSingleChatBotLoading(false));

            const response = await axios.get(`/chat-bot/get/${id}`);
            dispatch(slice.actions.setSingleChatBotDataSuccess(response.data?.data));
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isSingleChatBotLoading(true));
        }
    }
}

export function createChatBot(data) {
    //id -> mongoDB _id
    return async () => {
        try {
            const response = await axios.post(`/chat-bot/create`, data);
            dispatch(slice.actions.createChatBotDataSuccess(response.data?.data));
            handleSuccess(response, "ChatBot created successfully", dispatch);

            console.log("response")

            return response.data
        }
        catch (error) {
            throw error
        }
    }
}

export function updateAChatBot(data, id) {
    //id -> mongoDB _id
    return async () => {
        try {

            dispatch(slice.actions.isUpdateChatBotLoading(true));

            const response = await axios.put(`/chat-bot/update/${id}`, data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            dispatch(slice.actions.updateChatBotDataSuccess(
                { data, id }
            ));

            handleSuccess(response, "ChatBot updated successfully", dispatch);

            return response.data?.data
        }
        catch (error) {
            handleError(error, dispatch);
            throw error
        }
        finally {
            dispatch(slice.actions.isUpdateChatBotLoading(false));
        }
    }
}


export function deleteChatBot(chatBot_id) {
    //id -> mongoDB _id
    return async () => {
        try {
            const response = await axios.delete(`/chat-bot/delete?chatBot_id=${chatBot_id}`);
            dispatch(slice.actions.deleteChatBotDataSuccess(
                { data: response.data?.data, chatBot_id }
            ));
            handleSuccess(response, "ChatBot deleted successfully", dispatch);

            return response.data?.data
        }
        catch (error) {
            handleError(error, dispatch);
        }
    }
}
