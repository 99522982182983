// project imports
// import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from '../layout/MinimalLayout';
import NavMotion from '../layout/NavMotion';
import CheckoutPage from '../views/pages/others/chekoutPage';
import ContactUsPage from '../views/pages/others/contactUsPage';
import LandingPage from '../views/pages/landing-page';
import PrivacyPolicyPage from '../views/pages/others/pricacyPolicyPage.js';
import TermsAndConditionsPage from '../views/pages/others/termsAndConditionsPage.js';
import Research from '../views/pages/landing-page/Research.js';
import AboutUs from '../views/pages/landing-page/aboutUsPage.js';
import RefundPolicyPage from '../views/pages/others/refundPolicyPage.js';

import AuthPage from '../views/pages/auth-page/index';
import AcceptInvitationPage from '../views/pages/others/acceptInvitationPage'
import AuthGuard from '../utils/guards/AuthGuard.js';
import TwoFactorSetupPage from '../views/pages/others/twoFactorSetupPage.js';
import TwoFactorDisablePage from '../views/pages/others/twoFactorDisablePage.js';

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <MinimalLayout />
        </NavMotion>
    ),
    children: [
        {
            path: 'login',
            element: <AuthPage />
        },
        {
            path: 'sign-up',
            element: <AuthPage />
        },
        {
            path: 'auth/two-factor',
            element: <AuthPage />
        },
        {
            path: 'forgot-password',
            element: <AuthPage />
        },
        {
            path: 'contact-us',
            element:
                <LandingPage type="second">
                    <ContactUsPage />
                </LandingPage>
        },
        {
            path: 'about-us',
            element:
                <LandingPage type="second">
                    <Research padding='6rem' />
                </LandingPage>
        },
        {
            path: 'product',
            element:
                <LandingPage type="second">
                    <AboutUs padding='6rem' />
                </LandingPage>
        },
        {
            path: 'privacy-policy',
            element:
                <LandingPage>
                    <PrivacyPolicyPage />
                </LandingPage>
        },
        {
            path: 'refund-policy',
            element:
                <LandingPage>
                    <RefundPolicyPage />
                </LandingPage>
        },
        {
            path: 'terms-and-conditions',
            element:
                <LandingPage>
                    <TermsAndConditionsPage />
                </LandingPage>
        },
        {
            path: "invite/accept",
            element: (
                <AcceptInvitationPage />
            )
        },

        //authenticated routes without main layout
        {
            path: 'payment/checkout',
            element:
                <AuthGuard>
                    <CheckoutPage />
                </AuthGuard >
        },
        {
            path: '/dashboard/user-profile/twofa/setup',
            element:
                <AuthGuard>
                    <TwoFactorSetupPage/>
                </AuthGuard >
        },
        {
            path: '/dashboard/user-profile/twofa/disable',
            element:
                <AuthGuard>
                    <TwoFactorDisablePage/>
                </AuthGuard >
        },
    ]
};

export default LoginRoutes;
