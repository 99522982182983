// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconPhone, IconRobot, IconHistory, IconHeadset, IconUser, IconCreditCard, IconCode, IconPhoneOutgoing, IconAnalyze } from '@tabler/icons';

const icons = {
    IconDashboard,
    IconPhone,
    IconRobot,
    IconHistory,
    IconHeadset,
    IconUser,
    IconCreditCard,
    IconCode,
    IconPhoneOutgoing,
    IconAnalyze
};

// ==============================|| MENU ITEMS ||============================== //

const UnderMaintenance = ({ name }) => {
    return (
        <div className='flex gap-2'>
            <p>{name}</p>
            <sup className='text-[10px] font-semibold mt-[8px]'>Beta</sup>
        </div>
    )
}

const adminDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id=" " />,
    icon: icons.IconDashboard,
    type: 'group',
    class: 'dashboard_tab',
    children: [
        {
            id: 'numbers',
            title: <FormattedMessage id="Numbers" />,
            type: 'item',
            url: '/dashboard/numbers',
            icon: icons.IconPhone,
            tempIcon: 'sharp-call',
            class: 'numbers_tab'
        },
        {
            id: 'assistant',
            title: <FormattedMessage id="Assistants" />,
            type: 'collapse',
            icon: icons.IconRobot,
            class: 'assistants_tab',
            children: [
                {
                    id: 'agents',
                    title: <FormattedMessage id="General Agents" />,
                    type: 'item',
                    url: '/dashboard/assistants/other',
                    breadcrumbs: false,
                    class: 'agents_tab',
                },
                {
                    id: 'financial_agent',
                    title: <FormattedMessage id="Financial Agents" />,
                    type: 'item',
                    url: '/dashboard/assistants/financial',
                    breadcrumbs: false
                },
            ]

        },
        // {
        //     id: 'ai manager',
        //     title: <UnderMaintenance name="Ai Manager" />,
        //     type: 'item',
        //     url: '/dashboard/ai-manager',
        //     icon: icons.IconAnalyze,
        //     breadcrumbs: false,
        //     class: 'ai_manager_tab',
        // },
        {
            id: 'call history',
            title: <FormattedMessage id="Call History" />,
            type: 'item',
            url: '/dashboard/call-history',
            icon: icons.IconHistory,
            breadcrumbs: false,
            class: 'call_history_tab',
        },
        {
            id: 'outbound_call',
            title: <FormattedMessage id="Outbound Call" />,
            type: 'item',
            url: '/dashboard/outbound-call',
            icon: icons.IconPhoneOutgoing,
            breadcrumbs: false,
            class: 'outbound_call_tab',
        },
        // {
        //     id: 'subscription',
        //     title: <FormattedMessage id="Subscription" />,
        //     type: 'item',
        //     url: '/dashboard/subscription-plan',
        //     icon: icons.IconCreditCard,
        //     breadcrumbs: false
        // },
        {
            id: 'developer',
            title: <UnderMaintenance name="Developer" />,
            type: 'item',
            url: '/dashboard/developer/api-keys',
            icon: icons.IconCode,
            breadcrumbs: false,
            class: 'api_key_tab',
        },
        {
            id: 'support',
            title: <FormattedMessage id="Support" />,
            type: 'item',
            url: '/dashboard/support',
            icon: icons.IconHeadset,
            breadcrumbs: false,
            class: 'support_tab',
        },
    ]
};

export default adminDashboard;
