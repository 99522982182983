import CryptoJS from 'crypto-js';

const types = [
    'PERSIST',  // FOR EXAMPLE DEVICE ID
    'OTHERS',   // ANYTHING, ACTIVE ORG, FILTER QUERIES ETC.
    'SESSION',  // FOR EXAMPLE, REFRESH TOKEN, SESSION ID,
    'AUTH'      // FOR EXAMPLE ACCESS TOKEN.
];

export class StorageManager {
    constructor(name, type, secure = false, value = null) {
        if (!types.includes(type)) {
            throw new Error(`Invalid type. Allowed types are: ${types.join(', ')}`);
        }
        this.name = name;
        this.type = type;
        this.secure = secure;
        this.value = value;
        this.secretKey = 'jasbdu$H@H#()*&663';
    }

    // Format the key using name and type
    formatGenerator() {
        return `${this.type}$$_${this.name}`;
    }

    // Encrypt the value if secure, else return plain
    encryptValue(value) {
        return this.secure ? CryptoJS.AES.encrypt(value, this.secretKey).toString() : value;
    }

    // Decrypt the value if secure, else return plain
    decryptValue(value) {
        if (this.secure) {
            const bytes = CryptoJS.AES.decrypt(value, this.secretKey);
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        return value;
    }

    // Set value in local storage with optional encryption
    setStorage() {
        const formattedKey = this.formatGenerator();
        const storageValue = this.encryptValue(this.value);
        localStorage.setItem(formattedKey, JSON.stringify(storageValue));
    }

    // Get value from local storage with optional decryption
    getStorage() {
        const formattedKey = this.formatGenerator();
        const storedValue = JSON?.parse(localStorage.getItem(formattedKey));
        return storedValue ? this.decryptValue(storedValue) : null;
    }

    // Delete item from local storage
    deleteStorage() {
        const formattedKey = this.formatGenerator();
        localStorage.removeItem(formattedKey);
    }

    // Remove all local storage items except those with 'PERSIST' type
    static clearNonPersistent() {
        Object.keys(localStorage).forEach(key => {
            if (!key.startsWith('PERSIST$$_')) {
                localStorage.removeItem(key);
            }
        });
    }
}

// Example Usage
// const secureStorage = new StorageManager('userData', 'AUTH', true, 'my_secret_data');
// secureStorage.setStorage(); // Set encrypted value
// console.log(secureStorage.getStorage()); // Get decrypted value
// secureStorage.deleteStorage(); // Remove from storage

// Clear all non-PERSIST storage items
// StorageManager.clearNonPersistent();

console.log("CAME HERE")
