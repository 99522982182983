import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useSelector } from '../../store';
import NoPermissionPage from '../../views/pages/others/NoPermissionPage';

//Read guard
export const NumbersReadGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isReadAllowed = member_permission?.numbers?.view_numbers

    return isReadAllowed ? children : <NoPermissionPage />
};

NumbersReadGuard.propTypes = {
    children: PropTypes.node
};

//Write guard
export const NumbersSettingGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isWriteAllowed = member_permission?.numbers?.edit_setting

    return isWriteAllowed ? children : <NoPermissionPage />
};

NumbersSettingGuard.propTypes = {
    children: PropTypes.node
};

//Buy Number guard
export const NumberBuyGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isBuyAllowed = member_permission?.numbers?.buy_number

    return isBuyAllowed ? children : <NoPermissionPage />
};

NumberBuyGuard.propTypes = {
    children: PropTypes.node
};


export const NumbersSettingDisabledGuard = () => {
    const { member_permission } = useSelector((state) => state.account)
    
    const isWriteAllowed = member_permission?.numbers?.edit_setting

    console.log("isWriteAllowed: ", isWriteAllowed)

    return isWriteAllowed ? false : true;
};
