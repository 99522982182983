import React from 'react';
import './LoadingOverlay.css';
import { useSelector } from '../../store';

const LoadingOverlay = () => {
  const { show } = useSelector((state) => state.overlayLoading)

  if (!show) return null;

  return (
    <div className="loading-overlay flex flex-col gap-2">
      <div className="spinner"></div>
      <p className='text-md text-white'>Getting ready...</p>
    </div>
  );
};

export default LoadingOverlay;