
import React, { useState, useEffect } from 'react';
import './style.css';

const NetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showRestoredMessage, setShowRestoredMessage] = useState(false);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setShowRestoredMessage(true);
            setTimeout(() => {
                setShowRestoredMessage(false);
            }, 3000); // Show the message for 3 seconds
        };

        const handleOffline = () => {
            setIsOnline(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return (
        <>
            {!isOnline && (
                <div className="offline-container">
                    <p className="offline-text">Network connection lost</p>
                </div>
            )}
            {showRestoredMessage && (
                <div className="online-container">
                    <p className="online-text">Network restored</p>
                </div>
            )}
        </>
    );
};

export default NetworkStatus;

