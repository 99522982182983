import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';  // Use Link from react-router-dom
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { NavigateNext } from '@mui/icons-material';
import Link from '@mui/material/Link';  // Use Material-UI Link for styling
import Box from '@mui/material/Box';  // Use Box to apply z-index

const BreadcrumbsNav = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);  // Split and filter empty segments

    return (
        <Box
            sx={{
                position: 'relative',
                zIndex: 10000,
                padding: '20px 40px',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
            }}
        >  {/* Higher z-index */}
            <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                {pathnames.map((value, index) => {
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    // For the last item in the breadcrumb, just show text (no link)
                    const isLast = index === pathnames.length - 1;
                    return isLast ? (
                        <Typography key={to} className='text-primary'>
                            {value}
                        </Typography>
                    ) : (
                        <Link
                            key={to}
                            component={RouterLink}  // Use react-router-dom Link for navigation
                            to={to === '/dashboard' ? '/dashboard/numbers' : to}
                            sx={{
                                textDecoration: 'none',
                                color: 'black',
                                '&:hover': { textDecoration: 'underline' }
                            }}
                        >
                            {value}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </Box>
    );
};

export default BreadcrumbsNav;
