import PropTypes from 'prop-types';

import { useSelector } from '../../store';
import NoPermissionPage from '../../views/pages/others/NoPermissionPage';

//Read guard
export const ObcReadGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isReadAllowed = member_permission?.outbound_call?.view_obc

    return isReadAllowed ? children : <NoPermissionPage />
};

ObcReadGuard.propTypes = {
    children: PropTypes.node
};


//Read guard
export const ObcWriteGuard = ({ children }) => {
    const { member_permission } = useSelector((state) => state.account)

    const isReadAllowed = member_permission?.outbound_call?.create_obc

    return isReadAllowed ? children : <NoPermissionPage />
};

ObcWriteGuard.propTypes = {
    children: PropTypes.node
};
