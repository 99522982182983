
import React, { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';
import { useSelector, useDispatch } from 'react-redux';
import { setStepIndex, startTour, stopTour, resetTour } from '../../store/slices/onboardingTour';
import { StorageManager } from '../../utils/storageManager';
import { StepOneContent, StepTwoContent, StepThreeContent, StepFourContent, StepFiveContent, StepSixContent, StepSevenContent } from './Steps';
import { openConfirmationModal } from '../../store/slices/confirmationModal';

const DashboardTour = () => {
    const dispatch = useDispatch();
    const { run, isReady, stepIndex } = useSelector((state) => state.onboardingTour);

    const navigate = useNavigate();
    const location = useLocation();

    const tourSteps = [
        {
            target: '.numbers_tab',
            content: (
                <StepOneContent />
            ),
            data: {
                previous: '',
                next: '/dashboard/numbers',
                isLastStep: false,
            },
            disableBeacon: true,
        },
        {
            target: '.buy_number_button',
            content: (
                <StepTwoContent />
            ),
            data: {
                previous: '',
                next: '/dashboard/numbers/buy',
                isLastStep: false,
            },
            disableBeacon: true,

        },
        {
            target: '.country_filter_search_bar',
            content: (
                <StepThreeContent />
            ),
            data: {
                previous: '/dashboard/numbers',
                next: '',
                isLastStep: false,
            },
            disableBeacon: true,

        },
        {
            target: '.assistants_tab',
            content: (
                <StepFourContent />
            ),
            data: {
                previous: '',
                next: '/dashboard/assistants/other',
                isLastStep: false,
            },
            disableBeacon: true,

        },
        {
            target: '.create_agent_button',
            content: (
                <StepFiveContent />
            ),
            data: {
                previous: '/dashboard/numbers/buy',
                next: '/dashboard/numbers',
                isLastStep: false,
            },
            disableBeacon: true,

        },
        {
            target: '.number_setting_button',
            content: (
                <StepSixContent />
            ),
            data: {
                previous: '/dashboard/assistants/other',
                next: '/dashboard/numbers/setting/66eb0ad03106765c0f05ef3d',
                isLastStep: false,
            },
            disableBeacon: true,

        },
        {
            target: '.assign_agent',
            content: (
                <StepSevenContent />
            ),
            data: {
                previous: '/dashboard/numbers',
                next: '',
                isLastStep: true,
            },
            disableBeacon: true,
        },
    ];

    const checkTargetExists = useCallback((target) => {
        return document.querySelector(target) !== null;
    }, []);

    const handleNavigation = useCallback(() => {
        const currentStep = tourSteps[stepIndex];
        if (!currentStep) return;

        dispatch(stopTour());

        const checkTarget = setInterval(() => {
            if (currentStep.target && document.querySelector(currentStep.target)) {
                if (isReady) {
                    dispatch(startTour());
                }
                clearInterval(checkTarget);
            }
        }, 500);

        return () => clearInterval(checkTarget);
    }, [stepIndex, checkTargetExists, dispatch, tourSteps]);

    useEffect(() => {
        if (run) return; // Prevent running if already ready or running
        handleNavigation();
    }, [location.pathname, handleNavigation, run, isReady]);


    useEffect(() => {
        new StorageManager('ONBOARDING_TOUR_STEP_INDEX', 'OTHERS', false, stepIndex).setStorage();
    }, [stepIndex]);

    const handleJoyrideCallback = async (data) => {
        const { action, status, step, type } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            dispatch(resetTour());
            return;
        }

        const isPreviousAction = action === 'prev';
        const newStepIndex = isPreviousAction ? stepIndex - 1 : stepIndex + 1;
        const { previous, next, isLastStep } = step.data;

        // Determine if the step is the last one based on `step:after` and `next`
        if (type === 'step:after' && isLastStep && !step.data.next) {
            dispatch(resetTour())
            await new Promise(resolve => setTimeout(resolve, 500))
            dispatch(
                openConfirmationModal({
                    open: true,
                    message: "Welcome to RingUs! Let's get you started with a quick tutorial.",
                    modalType: "ONBOARDING_TOUR_END_MODAL",
                    closable: false,
                    handleConfirm: async () => {
                        navigate('/dashboard/quick-start')
                    },
                })
            );
            return;
        }

        if (type === 'step:after') {
            dispatch(stopTour());
            dispatch(setStepIndex(newStepIndex));

            if (isPreviousAction && previous) {
                navigate(previous);
            }
            else if (!isPreviousAction && next) {
                navigate(next);
            }
        }
    };

    //if joyride is not running.
    if (!run) return null;

    return (
        <Joyride
            steps={tourSteps}
            stepIndex={stepIndex}
            run={run}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            disableCloseOnEsc={true}
            disableOverlayClose={true}
            callback={handleJoyrideCallback}
            locale={{
                last: 'Finish',
                next: 'Next',
                skip: 'Skip',
                back: 'Back',
            }}
            styles={{
                options: {
                    arrowColor: '#eee',
                    backgroundColor: '#fff',
                    overlayColor: 'rgba(0, 0, 0, 0.5)',
                    primaryColor: '#169CD9',
                    textColor: '#333',
                    zIndex: 6000,
                },
            }}
        />
    );
};

export default DashboardTour;

