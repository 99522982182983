/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    personalAssistants: null,
    singlePersonalAssistants: null,
    ttsModelData: null,
    ttsModelProviders: null,
    providerVoiceIds: null,
    loading: false,
    updatePersonalAssistantLoading: false,
};

const slice = createSlice({
    name: 'personalAssistant',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        isUpdatePersonalAssistantLoading(state, action) {
            state.updatePersonalAssistantLoading = action.payload;
        },

        setPersonalAssistantDataSuccess(state, action) {
            state.personalAssistants = action.payload;
        },

        setSinglePersonalAssistantsDataSuccess(state, action) {
            state.singlePersonalAssistants = action.payload;
        },

        setTtsModelDataSuccess(state, action) {
            // state.ttsModelProviders = action.payload?.providers;
            // state.providerVoiceIds = action.payload?.voiceIds;
            state.ttsModelData = action.payload?.data;
        },

        createPersonalAssistantDataSuccess(state, action) {
            state.personalAssistants = [...state.personalAssistants, action.payload];
        },

        updatePersonalAssistantDataSuccess(state, action) {
            const index = state.personalAssistants.findIndex(personalAssistant => personalAssistant._id === action.payload.id);
            const personalAssistantData = state.personalAssistants[index];
            const newData = { ...personalAssistantData, ...action.payload.data };
            state.personalAssistants[index] = newData
        },

        deletePersonalAssistantDataSuccess(state, action) {
            const newPersonalAssistants = state.personalAssistants.filter(personalAssistant =>  personalAssistant._id !== action.payload.personalAssistant_id);
            state.personalAssistants = newPersonalAssistants;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllPersonalAssistants() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const response = await axios.get(`/personal-assistant/all`);
            dispatch(slice.actions.setPersonalAssistantDataSuccess(response.data?.data));

            dispatch(slice.actions.isLoading(false));
        }
        catch (err) {
            dispatch(slice.actions.isLoading(false));
            dispatch(slice.actions.hasError(err));
        }
    }
}

export function getSinglePersonalAssistants(id) {
    //id -> mongoDB _id
    return async () => {
        try {
            const response = await axios.get(`/personal-assistant/get/${id}`);
            dispatch(slice.actions.setSinglePersonalAssistantsDataSuccess(response.data?.data));
        }
        catch (err) {
            dispatch(slice.actions.hasError(err));
        }
    }
}

export function createPersonalAssistant(data) {
    //id -> mongoDB _id
    return async () => {
        try {
            const response = await axios.post(`/personal-assistant/create`, data);
            dispatch(slice.actions.createPersonalAssistantDataSuccess(response.data?.data));
            return response.data
        }
        catch (error) {
            throw error
        }
    }
}

export function updatePersonalAssistant(data, id) {
    //id -> mongoDB _id
    return async () => {
        try {

            dispatch(slice.actions.isUpdatePersonalAssistantLoading(true));

            const response = await axios.put(`/personal-assistant/update/${id}`, data);
            dispatch(slice.actions.updatePersonalAssistantDataSuccess(
                { data, id }
            ));
            
            dispatch(slice.actions.isUpdatePersonalAssistantLoading(false));
            return response.data?.data
        }
        catch (error) {
            dispatch(slice.actions.isUpdatePersonalAssistantLoading(false));
            throw error
        }
    }
}

export function getTtsModelInfo() {
    return async () => {
        try {
            const response = await axios.get(`/personal-assistant/tts_model`);
            dispatch(slice.actions.setTtsModelDataSuccess(response.data));
            return response.data?.data;
        }
        catch (err) {
            throw err
        }
    }
}

export function deletePersonalAssistant(personalAssistant_id) {
    //id -> mongoDB _id
    return async () => {
        try {
            const response = await axios.delete(`/personal-assistant/delete?personalAssistant_id=${personalAssistant_id}`);
            dispatch(slice.actions.deletePersonalAssistantDataSuccess(
                {data: response.data?.data, personalAssistant_id}
            ));
            return response.data?.data
        }
        catch (error) {
            throw error
        }
    }
}
