import { IconSelector, IconCalendarDue } from "@tabler/icons";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react/dist/iconify.js";

const selector_style = {
    width: '1.1rem',
    height: '1.1rem',
    color: '#0C90D0',
    marginRight: '8px',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'rotate(180deg)', // Rotate on hover (optional)
    },
}
export const StyledIconSelector = styled(IconSelector)(({ theme }) => (selector_style));


const calender_style = {
    width: '0.8em',
    height: '0.8em',
    transition: 'transform 0.3s ease-in-out',
}
const IconCalender = styled(Icon)(({ theme }) => (calender_style));

export const StyledIconCalender = <IconCalender icon="uil:calender" />
export const StyledIconCalenderTwo = () => (
    <IconCalender icon="uil:calender" />
)
