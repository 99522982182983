import React from 'react';
import LogoSection from '../../../layout/MainLayout/LogoSection';
import { useDispatch, useSelector } from '../../../store';
import { acceptInvitation } from '../../../store/slices/organization';
import { CircularProgress } from '@mui/material';

const AcceptInvitation = () => {
    const dispatch = useDispatch()
    const { loading } = useSelector((state) => state.organization)
    const [error, setError] = React.useState('')
    const [orgData, setOrgData] = React.useState(null)

    //taking invite_code from the query params
    const inviteCode = new URLSearchParams(window.location.search).get('invite_code');

    React.useEffect(() => {
        async function fetch() {
            if (inviteCode) {
                try {
                    const data = await dispatch(acceptInvitation(inviteCode))
                    setOrgData(data)
                }
                catch (error) {
                    const errorMessage = error?.message;
                    setError(errorMessage)
                }
            }
            else {
                setError('Invalid invite code')
            }
        }
        fetch()
    }, [inviteCode])


    return (
        <div className="flex flex-col items-center justify-start h-screen bg-gray-100 pt-[4rem]">
            <div className='flex items-center gap-3 justify-center'>
                <LogoSection />
                <h1 className='text-2xl font-bold'>
                    Ring
                    <span className='text-[#169CD9]'>Us</span>
                </h1>
            </div>

            {!loading ?

                error ?
                    <div className="rounded-lg p-8 max-w-md text-center flex flex-col h-full items-center justify-center">
                        <h3 className="text-2xl font-semibold mb-2">Oops!</h3>
                        <p className="text-tertiary">
                            {error}
                        </p>
                    </div>
                    :
                    <div className="rounded-lg p-8 max-w-md text-center flex flex-col h-full items-center justify-center">
                        <h3 className="text-xl font-semibold mb-2">Invite accepted</h3>
                        <p className="text-tertiary">You've been added to the organization:</p>
                        <h5 className="text-lg font-medium my-4">{orgData?.org_name}</h5>
                        <button
                            className="bg-primary text-white px-4 py-2 rounded hover:bg-primary"
                            onClick={() => window.location.href = "https://dashboard.ringus.ai"}
                        >
                            Continue
                        </button>
                    </div>
                :
                <div className="rounded-lg p-8 max-w-md text-center flex flex-col h-full items-center justify-center">
                    <CircularProgress style={{ height: '30px', width: '30px' }} />
                    <h3 className="text-xl font-semibold mb-2">Please wait</h3>
                    <p className="text-tertiary">We are looking for your invite</p>
                </div>
            }
        </div>
    );
};

export default AcceptInvitation;
