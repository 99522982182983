import React, { useEffect, useState } from 'react'

import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { IconArrowRight } from '@tabler/icons'
import aboutUsImage from '../../../assets/images/aboutUsImage.png'

const AboutUs = ({ padding = '0px' }) => {

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: -100 },
            }}
            transition={{ duration: 0.7 }}
            className="pt-10 bg-[--main-color]"
            id="about"
            style={{ paddingBottom: padding, paddingTop: '6rem' }}
        >
            <div className="flex items-center lg:flex-row flex-col justify-between px-10">

                <div className=" shadow-lg rounded-lg md:w-[100%] h-full hidden md:flex lg:hidden">
                    <img loading="lazy" src={aboutUsImage} alt="" className="h-full w-full" />
                </div>

                <div className="flex flex-col justify-center w-[100%] ">
                    <div className="flex  flex-col md:items-start items-center justify-center">

                        <h1 className=" py-4 text-3xl md:w-[90%]   text-center md:text-left  md:text-5xl font-[600]">
                            Create an AI Agent For Your Business
                        </h1>
                    </div>

                    <div className="rounded-lg shadow-lg md:w-[100%] h-full flex md:hidden">
                        <img loading="lazy" src={aboutUsImage} alt="" className="h-full w-full" />
                    </div>

                    <p
                        className="md:px-0 text-[15px] md:text-[18px] text-justify font-[400] md:my-4 my-2  lg:w-[80%] w-[100%]"
                        style={{ lineHeight: '30px' }}
                    >
                        Unleash the power of{' '}
                        <span className="text-[#169CD9]">
                            RingUs, a revolutionary Web platform
                        </span>{' '}
                        where we revolutionize the way businesses handle customer support interactions. Our innovative platform allows you to create an AI powered personalized customer support agent, designed to seamlessly provide resolution to customer queries.
                        <span className="text-[#169CD9]">
                            &nbsp;RingUs is your AI companion.
                        </span>{' '}
                    </p>
                    <div className="flex md:items-start items-center md:justify-start justify-center mt-8">
                        <Link to="https://ringus.ai/login">
                            <button className=" rounded-[56px] flex items-center text-white bg-[#169CD9] px-10 py-2 text-center">
                                Get Started
                                <IconArrowRight
                                    className="bg-transparent ml-2 text-center"
                                />
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="w-[100%] h-full rounded-lg overflow-hidden shadow-lg hidden md:hidden lg:block">
                    <div className=" aspect-w-4 aspect-h-3 mx-auto ">
                        <img src={aboutUsImage} alt='icon' />
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default AboutUs
