import React from 'react';

const PrivacyPolicyPage = () => {
    return (
        <>
            <div className="max-w-4xl mx-auto p-8 mt-20">
                <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
                <p className="mb-4">
                    This Privacy Policy describes how Ringus.AI, a product of Techlane AI Private Limited (referred to as the "Company," "we," "us," or "our") collects, uses, discloses, and safeguards personal information obtained from clients, users of our services, and visitors to our website.
                </p>

                <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
                <h3 className="text-lg font-semibold mb-2 ml-4">1.1 Personal Information</h3>
                <p className="mb-4 ml-8">
                    We may collect personal information, including but not limited to names, email addresses, contact information, and other information provided to us when using our services or contacting us.
                </p>
                <h3 className="text-lg font-semibold mb-2 ml-4">1.2 Usage Data</h3>
                <p className="mb-4 ml-8">
                    We collect data on how our website and services are accessed and used. This data may include IP addresses, browser information, device details, and access times.
                </p>

                <h2 className="text-2xl font-semibold mb-4">2. How We Use Information</h2>
                <h3 className="text-lg font-semibold mb-2 ml-4">2.1 To Provide Services</h3>
                <p className="mb-4 ml-8">
                    We use collected information to deliver our services, fulfill requests, and communicate with clients.
                </p>
                <h3 className="text-lg font-semibold mb-2 ml-4">2.2 Communication</h3>
                <p className="mb-4 ml-8">
                    We may contact clients for updates, service-related notifications, or marketing purposes.
                </p>
                <h3 className="text-lg font-semibold mb-2 ml-4">2.3 Website Analytics</h3>
                <p className="mb-4 ml-8">
                    We use analytics tools to evaluate website usage and improve the user experience.
                </p>

                <h2 className="text-2xl font-semibold mb-4">3. Data Sharing</h2>
                <p className="mb-4">
                    We do not sell, rent, or trade personal information with third parties. We may share personal information as follows:
                </p>
                <h3 className="text-lg font-semibold mb-2 ml-4">3.1 Service Providers</h3>
                <p className="mb-4 ml-8">
                    We may use third-party service providers to support our business operations. These providers may have access to personal information as necessary to perform their functions.
                </p>
                <h3 className="text-lg font-semibold mb-2 ml-4">3.2 Legal Compliance</h3>
                <p className="mb-4 ml-8">
                    We may disclose information if required by law, regulation, or legal process.
                </p>

                <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
                <p className="mb-4">
                    We employ appropriate technical and organizational measures to protect personal information. However, no method of transmission over the internet or electronic storage is completely secure.
                </p>

                <h2 className="text-2xl font-semibold mb-4">5. Data Retention</h2>
                <p className="mb-4">
                    We retain personal information as long as necessary for the purposes outlined in this Privacy Policy. Clients may request data deletion, subject to legal and contractual obligations.
                </p>

                <h2 className="text-2xl font-semibold mb-4">6. Client Choices</h2>
                <h3 className="text-lg font-semibold mb-2 ml-4">6.1 Access and Correction</h3>
                <p className="mb-4 ml-8">
                    Clients may access, update, or correct their personal information.
                </p>
                <h3 className="text-lg font-semibold mb-2 ml-4">6.2 Unsubscribe</h3>
                <p className="mb-4 ml-8">
                    Clients may unsubscribe from our promotional communications.
                </p>

                <h2 className="text-2xl font-semibold mb-4">7. Children's Privacy</h2>
                <p className="mb-4">
                    We do not knowingly collect personal information from children.
                </p>

                <h2 className="text-2xl font-semibold mb-4">8. Changes to This Policy</h2>
                <p className="mb-4">
                    This Privacy Policy may be updated to reflect changes in our practices and legal requirements. We will provide notice of significant changes.
                </p>

                {/* <h2 className="text-2xl font-semibold mb-4">9. Contact Information</h2>
                <p className="mb-4">
                    For questions or requests related to this Privacy Policy, please contact us at:
                </p>
                <ul className="list-disc list-inside mb-4 ml-8">
                    <li>Company Name: Techlane AI Private Limited</li>
                    <li>Address: Unit No. 505, KLJ Tower North, Netaji Subhash Place, Pitampura, New Delhi - 110034</li>
                    <li>Email: admin@techlane.ai</li>
                    <li>Phone: 9773665117</li>
                </ul> */}
                <section className="mb-6 mt-6">
                    <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
                    <p>
                        If you have any questions about these Terms and Conditions, please contact us at:
                    </p>
                    <address className="not-italic mt-4 leading-[1.3rem]">
                        <span className='font-semibold'>Techlane AI Private Limited</span><br />
                        Unit No. 505, KLJ Tower North, Netaji Subhash Place, Pitampura, Delhi - 110034 <br />
                        <a href="mailto:admin@techlane.ai" className="text-blue-600 hover:underline">
                            admin@techlane.ai
                        </a>
                        <br />
                        +91 9773665117
                    </address>
                </section>

                <p className="mb-4">This Privacy Policy is effective as of 17 March 2023.</p>
            </div>
        </>
    );
};

export default PrivacyPolicyPage;


{/* <section className="mb-6">
<div style={{ height: '100vh', width: '100%' }}>
    <iframe
        src={'https://ringus.s3.ap-south-1.amazonaws.com/ringus_privacypolicy.pdf'}
        width="100%"
        height="100%"
        style={{
            borderBottom: '10px solid',
            borderLeft: '10px solid',
            borderRight: '10px solid',
            borderRadius: '5px'
        }}
    />
</div>
</section> */}

{/* <section className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
                    <p>
                        If you have any questions about these Terms and Conditions, please contact us at:
                    </p>
                    <address className="not-italic mt-4 leading-[1.3rem]">
                        <span className='font-semibold'>Techlane AI Private Limited</span><br />
                        Unit No. 505, KLJ Tower North, Netaji Subhash Place, Pitampura, Delhi - 110034 <br />
                        <a href="mailto:admin@techlane.ai" className="text-blue-600 hover:underline">
                            admin@techlane.ai
                        </a>
                        <br />
                        +91 9773665117
                    </address>
                </section> */}