/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';

const initialState = {
    error: null,
    subscriptions: null,
    activeSubscription: null,
    subPlans: null,
    currencyRates: null,
    loading: false
};

const slice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        setSubscriptionDataSuccess(state, action) {
            state.subscriptions = action.payload;
        },

        setActiveSubscriptionDataSuccess(state, action) {
            state.activeSubscription = action.payload;
        },

        setSubscriptionPlanDataSuccess(state, action) {
            state.subPlans = action.payload?.data;
            state.currencyRates = action.payload?.currencyRates;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllSubscriptionPlans() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/subscription/get-plans`);
            dispatch(slice.actions.setSubscriptionPlanDataSuccess(response.data))
            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}

export function getActiveSubscriptionOfUser() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/subscription/user/get-one`);
            dispatch(slice.actions.setActiveSubscriptionDataSuccess(response.data?.data))

            return response.data?.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))
        }
    }
}
