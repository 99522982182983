export const getFirebaseErrorMessage = (error) => {
    const errorCode = error?.code;
    let message = "An unknown error occurred.";

    switch (errorCode) {
        case 'auth/invalid-email':
            message = "Please provide a valid email address and password.";
            break;
        case 'auth/user-disabled':
            message = "This account has been disabled";
            break;

        case 'auth/user-not-found':
            message = "Account not found. Please contact out support team";
            break;

        case 'auth/wrong-password':
            message = "Please provide a valid email address and password.";
            break;
        case 'auth/invalid-credential':
            message = "Please provide a valid email address and password.";
            break;

        case 'auth/invalid-login-credentials':
            message = "Please provide a valid email address and password.";
            break;

        case 'auth/email-already-in-use':
            message = "Email is already associated with another account";
            break;
        case 'auth/popup-closed-by-user':
            message = "Login popup closed";
            break;
        case 'auth/cancelled-popup-request':
            message = "Another popup is already opened";
            break;
        case 'auth/invalid-verification-code':
            message = "Invalid verification code";
            break;
        case 'auth/argument-error':
            message = "argument-error";
            break;
        case 'auth/operation-not-allowed':
            message = "Something went wrong. Please refresh and try again.";
            break;
        case 'auth/captcha-check-failed':
            message = "Something went wrong. Recaptcha check failed";
            break;
        case 'auth/too-many-requests':
            message = "Something went wrong.";
            break;
        case 'auth/provider-already-linked':
            message = "Something went wrong. Please refresh and try again.";
            break;
        case 'auth/unauthorized-domain':
            message = "Something went wrong. Please try again.";
            break;
        case 'auth/invalid-verification-code':
            message = "Invalid verification code.";
            break;
        default:
            message = message;
            break;
    }

    return message;
};