// File path: src/pages/ContactUsPage.js

import React, { useState } from 'react';
import { TextField, Button, Paper, Grid, TextareaAutosize, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '../../../store';
import { LocalPhone, Forum } from '@mui/icons-material';
import './contactUs.css'

const ContactUsPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        name: '',
        email: '',
        description: '',
    })

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        // Handle form submission logic here
    }

    return (
        <div className="flex flex-col items-center min-h-screen mt-20">

            <Grid container spacing={6} className='contactUsBg flex-col mt-1 text-white text-justify'>
                <h1 className="mb-3 text-2xl font-semibold">Get in touch</h1>
                <p className="text-center mt-10">
                    Want to get in touch? We'd love to hear from you. Here's how you can reach us.
                </p>
            </Grid>

            <Grid container spacing={6} justifyContent="center" className='z-50' >
                <Grid item xs={10} md={4} className='contactUsForm'>
                    <Paper className="p-6 rounded-md flex flex-col gap-3 items-center h-[520px]" elevation={3}>
                        <LocalPhone className=' mb-3 text-[50px]' />
                        <Typography variant="h5" className="text-center mb-4">Talk to Sales</Typography>
                        <Typography variant="body1" className="mb-4 text-center">
                            Interested in RingUs AI. Just pick up the phone to chat with a member of our sales team.
                        </Typography>
                        <Typography variant="h6" className="text-center mb-4">
                            <a href="tel:0008000503669" className="text-blue-500">+91 9773665117</a>
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={10} md={4} className='contactUsForm'>
                    <Paper className="p-6 rounded-md flex flex-col gap-3 items-center" elevation={3}>
                        <Forum className=' mb-3 text-[50px]' />

                        <Typography variant="h5" className="text-center mb-4">Contact Customer Support</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h1 className="text-md mb-1" >
                                    Name
                                    <span className='text-red-400'>*</span>
                                </h1>
                                <TextField
                                    fullWidth
                                    placeholder="Jhon Doe"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h1 className="text-md mb-1" >
                                    Email
                                    <span className='text-red-400'>*</span>
                                </h1>
                                <TextField
                                    fullWidth
                                    placeholder="abc@example.com"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h1 className="text-md mb-1" >
                                    Description
                                </h1>
                                <TextField
                                    fullWidth
                                    placeholder="description"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end" marginTop={2}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <h1 className="mb-3 text-2xl font-semibold my-10 text-center">Connect with one of our office</h1>

            <div className='flex flex-col md:flex-row items-center justify-center my-10 ' >
                <Grid item xs={10} md={5} style={{ display: 'flex', justifyContent: 'center' }} className='border border-1 border-gray-300 '>
                    {/* <iframe
                        className='xss:w-[300px] xs:w-[500px] sm:w-[600px] h-[450px]'
                        style={{ borderTop: '1px solid' }}
                        loading="lazy"
                        allowfullscreen
                        referrerpolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=Unit No. 505, KLJ Tower North, Netaji Subhash Place, Pitampura Delhi - 110034, India&zoom=9`}>
                    </iframe> */}
                </Grid>
                <div className='border border-1 border-gray-300 xss:w-[300px] xs:w-[500px] sm:w-[600px] md:w-fit' >
                    <Paper className="p-6 flex flex-col gap-3 items-center xss:h-[250px] md:h-[450px] " >
                        <Typography variant="h5" className="text-center mb-4 font-semibold">Headquarter</Typography>
                        <Typography variant="body1">
                            Unit No. 505, KLJ Tower North,<br />
                            Netaji Subhash Place, Pitampura<br />
                            Delhi - 110034, India
                        </Typography>
                        <Typography variant="h5" className="text-center mb-4 font-semibold">Phone</Typography>
                        <Typography variant="body1">
                            +91 9773665117<br />
                        </Typography>
                    </Paper>
                </div>
            </div>
        </div>
    );
};

export default ContactUsPage;
