/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { dispatch } from '../index';
import { handleError, handleSuccess } from '../../utils/apiResponseHandler.js';


const initialState = {
    error: null,
    testPhoneNumbers: null,
    loading: false
};

const slice = createSlice({
    name: 'testPhoneNumber',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        isLoading(state, action) {
            state.loading = action.payload;
        },

        setTestPhoneNumberDataSuccess(state, action) {
            state.testPhoneNumbers = action.payload;
        },

        createTestPhoneNumberDataSuccess(state, action) {
            state.testPhoneNumbers = [...state.testPhoneNumbers, action.payload];
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getAllTestPhoneNumbers() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true))

            const response = await axios.get(`/user/get/test_numbers`);
            dispatch(slice.actions.setTestPhoneNumberDataSuccess(response.data?.data));
        }
        catch (err) {
            handleError(err, dispatch);
        }
        finally {
            dispatch(slice.actions.isLoading(false))

        }
    }
}

export function createTestPhoneNumber(data) {
    return async () => {
        try {
            const response = await axios.post(`/user/create/test_number`, data);

            dispatch(slice.actions.createTestPhoneNumberDataSuccess(response.data?.data));
            return response.data;
        }
        catch (err) {
            handleError(err, dispatch);
        }
    }
}
