import React, { useState, useEffect } from 'react';
import Confetti from '../../../../assets/images/confetti.gif'

const ShowConfetti = ({ showConfetti, setShowConfetti }) => {

    useEffect(() => {
        if (showConfetti) {
            const timer = setTimeout(() => {
                setShowConfetti(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showConfetti]);

    return (
        <>
            {showConfetti ? <img src={Confetti} alt="Cool GIF" style={{ position: 'absolute' }} /> : <></>}
        </>
    );
};

export default ShowConfetti;
