/* eslint-disable */

import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from '../../../store';
import { closeConfirmationModal, showLoading } from '../../../store/slices/confirmationModal';
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { Icon } from '@iconify/react/dist/iconify.js';
import congratsGif from '../../../assets/images/congrats_gif.gif'
import confetti from '../../../assets/images/confetti.svg'
import yellowStar from '../../../assets/images/yellow_star.svg'
import { WarningIcon } from '../../../assets/icons';
import { resetTour, startTour } from '../../../store/slices/onboardingTour';
import { keyframes, styled } from '@mui/system';
import { MuiDialog } from '../../../ui-elements/MuiDialog';

// ===============================|| UI DIALOG - RESPONSIVE ||=============================== //

export default function ConfirmationModal() {
    const theme = useTheme();
    const navigate = useNavigate()

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch()
    const { open, handleConfirm, message, loading, modalType, closable } = useSelector((state) => state.confirmationModal)

    //-----------5s countdown timer----------------- 
    const [countdown, setCountdown] = useState(10);
    useEffect(() => {
        let timer;
        if (open && modalType === 'PURCHASED_PRODUCT') {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown <= 1) {
                        clearInterval(timer);
                        handleSubmit()
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [open]);
    //-----------End of count down logic-----------------------

    const handleClose = () => {
        dispatch(closeConfirmationModal());
    };

    const handleSubmit = async () => {
        await dispatch(showLoading())

        await handleConfirm();

        await dispatch(showLoading())
        dispatch(closeConfirmationModal());
    }


    return (
        <div >
            <MuiDialog
                open={open}
                handleClose={handleClose}
                closable={closable}
                element={
                    <>
                        {modalType === 'CONFIRMATION' ? (
                            <>
                                <DialogTitle id="responsive-dialog-title">Are you sure?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <Typography variant="body2" component="span">
                                            {message}
                                        </Typography>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ pr: 2.5, pb: 2 }}>
                                    <Button sx={{ color: theme.palette.error.dark }} autoFocus onClick={handleClose} color="secondary" variant="outlined" className='outlined-button'>
                                        cancel
                                    </Button>
                                    <LoadingButton type="button"
                                        variant="contained"
                                        className='outlined-button'
                                        size="small"
                                        onClick={handleSubmit}
                                        autoFocus
                                        loading={loading}
                                    >
                                        submit
                                    </LoadingButton>
                                </DialogActions>
                            </>
                        )
                            :
                            (
                                modalType === 'NULL_SUBSCRIPTION' ?
                                    <>
                                        <DialogTitle id="responsive-dialog-title" sx={{ textAlign: 'right', p: 1 }} className='relative'>
                                            <IconButton onClick={handleClose} size="small" className='absolute right-3'>
                                                <CloseIcon className='text-primary' />
                                            </IconButton>
                                        </DialogTitle>
                                        <DialogContent
                                            sx={{
                                                textAlign: 'center',
                                                px: 4,
                                                pb: 2,
                                                marginTop: '-50px',
                                            }}
                                            className='flex flex-col items-center justify-center'
                                        >
                                            <div className='bg-primary rounded-full w-[70px] h-[70px] flex items-center justify-center'>
                                                <Icon icon="uil:exclamation" className='text-[80px] text-white' />
                                            </div>

                                            <Typography sx={{ mt: 4, mb: 2 }} className='text-xl font-semibold text-primary'>
                                                Subscription Required!
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 2 }} className='text-tertiary'>
                                                You don't have any active subscription for this <br />{' '}
                                                service. Please subscribe to one of our<br />{' '}
                                                plans to access this feature.
                                            </Typography>
                                        </DialogContent>
                                        <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                                            <LoadingButton
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                View Plans
                                            </LoadingButton>
                                        </DialogActions>
                                    </>
                                    :
                                    modalType === 'UPGRADE_SUBSCRIPTION' ?
                                        <>
                                            <DialogTitle id="responsive-dialog-title" sx={{ textAlign: 'right', p: 1 }} className='relative'>
                                                <IconButton onClick={handleClose} size="small" className='absolute right-3'>
                                                    <CloseIcon className='text-primary' />
                                                </IconButton>
                                            </DialogTitle>
                                            <DialogContent
                                                sx={{
                                                    textAlign: 'center',
                                                    px: 4,
                                                    pb: 2,
                                                    marginTop: '-50px',
                                                }}
                                                className='flex flex-col items-center justify-center'
                                            >
                                                <div className='bg-primary rounded-full w-[70px] h-[70px] flex items-center justify-center'>
                                                    <Icon icon="uil:exclamation" className='text-[80px] text-white' />
                                                </div>

                                                <Typography sx={{ mt: 4, mb: 1 }} className='text-xl font-semibold text-primary'>
                                                    Upgrade Subscription!
                                                </Typography>
                                                <Typography variant="body1" sx={{ mb: 2 }} className='text-tertiary'>
                                                    You have exceeded the limit of your subscription. Please upgrade your
                                                    subscription to continue.
                                                </Typography>
                                            </DialogContent>
                                            <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                                                <LoadingButton
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                >
                                                    Upgrade Plan
                                                </LoadingButton>
                                            </DialogActions>
                                        </>
                                        :
                                        modalType === 'PURCHASED_PRODUCT' ?
                                            <>
                                                <DialogContent sx={{ textAlign: 'center', px: 4, pt: 0, pb: 2, py: 6 }} className='flex flex-col items-center justify-center'>
                                                    <img src={congratsGif} alt='congrats' className='h-[10rem] w-[10rem]' />

                                                    <Typography sx={{ mt: 2, mb: 1 }} className='text-2xl font-semibold text-primary'>
                                                        Congratulations!
                                                    </Typography>
                                                    <Typography variant="body1" sx={{ mb: 2 }} className='text-tertiary'>
                                                        You have successfully purchased the product.
                                                    </Typography>
                                                </DialogContent>
                                                <DialogActions sx={{ justifyContent: 'center', pb: 2 }} className='flex flex-col'>
                                                    <LoadingButton
                                                        variant="contained"
                                                        sx={{
                                                            px: 3
                                                        }}
                                                        onClick={handleSubmit}
                                                    >
                                                        Go to dashboard
                                                    </LoadingButton>

                                                    <p className='my-3'>Redirecting you in {countdown}s</p>
                                                </DialogActions>
                                            </>
                                            :
                                            modalType === 'REFRESH_TOKEN_EXPIRED' ?
                                                <>
                                                    <DialogContent
                                                        sx={{
                                                            textAlign: 'center',
                                                            px: 4,
                                                            pb: 2,
                                                            marginTop: '-50px',
                                                        }}
                                                        className='flex flex-col items-center justify-center'
                                                    >
                                                        <WarningIcon
                                                            height="70px"
                                                            width="70px"
                                                        />

                                                        <Typography sx={{ mt: 4, mb: 1 }} className='text-2xl font-semibold text-primary'>
                                                            Session Expired!
                                                        </Typography>
                                                        <Typography variant="body1" sx={{ mb: 2 }} className='text-tertiary'>
                                                            Your session has expired.<br />{' '}
                                                            Log in again to continue using RingUs
                                                        </Typography>
                                                    </DialogContent>
                                                    <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
                                                        <LoadingButton
                                                            variant="contained"
                                                            onClick={handleSubmit}
                                                            className='px-10'
                                                        >
                                                            Login
                                                        </LoadingButton>
                                                    </DialogActions>
                                                </>
                                                :
                                                modalType === 'ONBOARDING_TOUR_CONF_MODAL' ?
                                                    <>
                                                        <DialogContent
                                                            sx={{
                                                                textAlign: 'center',
                                                                px: 4,
                                                                pb: 2,
                                                            }}
                                                            className='flex flex-col items-center justify-center relative'
                                                        >
                                                            <div className='relative flex w-full'>
                                                                <img
                                                                    src={yellowStar}
                                                                    alt='star'
                                                                    loading='eager'
                                                                    className='h-[4rem] w-[4rem] absolute left-[4rem] top-[-4px]'
                                                                />
                                                                <img
                                                                    src={yellowStar}
                                                                    alt='star'
                                                                    loading='eager'
                                                                    className='h-[4rem] w-[4rem] absolute right-[4rem] top-[-4px]'
                                                                />
                                                            </div>

                                                            <Typography sx={{ mt: 2, mb: 2 }} className='text-2xl font-semibold text-primary'>
                                                                Welcome!
                                                            </Typography>

                                                            <Typography sx={{ mb: 1 }} className='text-lg font-semibold'>
                                                                Let’s start with a quick Dashboard tour
                                                            </Typography>
                                                            <Typography variant="body1" sx={{ mb: 2 }} className='text-tertiary'>
                                                                Explore your dashboard and learn how to manage agents, update details, and customize settings to suit your needs.
                                                            </Typography>
                                                        </DialogContent>
                                                        <DialogActions sx={{ justifyContent: 'center', pb: 2, gap: 3 }}>
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    dispatch(resetTour())
                                                                    handleSubmit()
                                                                }}
                                                                className='px-8 py-2 text-primary border-none hover:border-none'
                                                            >
                                                                I will explore
                                                            </Button>
                                                            <LoadingButton
                                                                variant="contained"
                                                                onClick={async () => {
                                                                    handleSubmit()
                                                                    await new Promise(resolve => setTimeout(resolve, 1000));
                                                                    dispatch(startTour())
                                                                }}
                                                                className='px-10'
                                                            >
                                                                Get Started
                                                            </LoadingButton>
                                                        </DialogActions>
                                                    </>
                                                    :
                                                    modalType === 'ONBOARDING_TOUR_END_MODAL' ?
                                                        <>
                                                            <DialogContent
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    px: 4,
                                                                    pb: 2,
                                                                }}
                                                                className='flex flex-col items-center justify-center'
                                                            >
                                                                <img
                                                                    src={confetti}
                                                                    alt='congrats'
                                                                    loading='eager'
                                                                    className='h-[6rem] w-[6rem]'
                                                                />
                                                                <Typography sx={{ mt: 1, mb: 2 }} className='text-2xl font-semibold'>
                                                                    Congratulations!
                                                                </Typography>

                                                                <Typography variant="body1" sx={{ mb: 2 }} className='text-tertiary'>
                                                                    You have completed the setup. You are now ready to start creating and managing your agents for impactful client interactions.
                                                                </Typography>
                                                            </DialogContent>
                                                            <DialogActions sx={{ justifyContent: 'center', pb: 2, gap: 3 }}>
                                                                <LoadingButton
                                                                    variant="contained"
                                                                    onClick={handleSubmit}
                                                                    className='px-10'
                                                                >
                                                                    Start Exploring
                                                                </LoadingButton>
                                                            </DialogActions>
                                                        </>
                                                        :
                                                        <></>
                            )
                        }
                    </>
                }
            />
        </div >
    );
}
