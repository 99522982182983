/* eslint-disable */
import React from 'react'
import Styles from "../styles/header.module.scss";
import authPage from '../assets/images/authPage.png'

export const handleRedirect = () => {
    window.location.href = 'https://ringus.ai'
}
function Header(props) {
    return (
        <>
            <div className={Styles.header}>
                <img
                    src={"https://ucarecdn.com/3c3b7987-6cd2-45c7-bf91-afb43baa83a3/authPage.png"}
                    alt='authPageImage'
                    className='w-full h-full'
                    style={{ objectFit: 'cover' }}
                />
                <div className='hidden lg:flex flex-col gap-4 w-full lg:w-[30%] lg:items-start items-center text-center lg:text-left absolute left-[100px]'>
                    <h2 className='text-xl sm:text-3xl font-semibold text-white' style={{ lineHeight: '40px' }}>
                        RingUs
                    </h2>
                    <p className='text-xs sm:text-base text-white leading-[30px]'>
                        Experience the future of AI calling with{' '}<br />our enterprise AI agents available 24/7.
                    </p>
                    <button
                        className="rounded-full py-3 sm:px-16 px-6 text-center border bg-white text-primary sm:text-base text-sm"
                        onClick={handleRedirect}
                    >
                        Learn more
                    </button>
                </div>
            </div >
        </>
    )
}

export default Header
