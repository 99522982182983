// @mui
import { Grid, Skeleton, TableCell, TableRow } from '@mui/material';
import { Stack } from '@mui/system';

// ----------------------------------------------------------------------

export default function AgentTemplateSkeletonLoader() {
    return (
        <div style={{ marginTop: '-15px' }} className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-x-8 gap-y-8 justify-start container'>
            {/* map to six count */}
            {Array.from({ length: 6 }).map((_, index) => (
                <div
                    className={`min-h-[200px] min-w-[280px] w-[280px] flex flex-col rounded-lg plan-card px-4 py-4`}>

                    <div className='flex flex-col items-start justify-start gap-2'>
                        <Skeleton variant="rectangular" width={40} height={40} />
                    </div>

                    <Skeleton variant="text" sx={{ fontSize: '1.25rem' }} height={70} />

                    <Skeleton variant="text" sx={{ fontSize: '1.25rem' }} height={50} />
                    <div className='flex flex-col gap-0'>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} height={20} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} height={20} width={'80%'} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} height={20} width={'40%'} />
                    </div>

                    <div className='flex items-center justify-between mt-4'>
                        <Skeleton variant="rounded" width={"100%"} height={40} className='rounded-full'/>
                    </div>
                </div>
            )
            )}
        </div>
    );
}

