/* eslint-disable */
import React, { useEffect } from 'react'
import TwoFaForm from "./TwoFaForm";
import Styles from "../../../styles/dialog.module.scss"
import Logo from '../../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../../store';

function TwoFaAuthPage(props) {
    const navigate = useNavigate();

    return (
        <div className={Styles.loginform}>
            <div name='logo' className={`text-[#000000B3] mb-5 flex items-center gap-1 ${Styles.logo} px-5 lg:px-6`}>
                <img src={Logo} alt="logo" style={{ height: '40px', width: '40px' }} />
                <p className='text-xl font-bold'>
                    <span className='text-[#133674]'>Ring</span>
                    <span className='text-[#169CD9]'>Us</span>
                </p>
            </div>
            <div className='px-5 lg:px-10 mt-[80px]'>
                <TwoFaForm />
            </div>

        </div>
    )
}

export default TwoFaAuthPage
