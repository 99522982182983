import { Link as RouterLink } from 'react-router-dom';  

// material-ui
import { Link } from '@mui/material';
// project imports
import Logo from '../../../assets/images/logo.png';


// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to='numbers' aria-label="logo">
        <img src={Logo} alt="logo" style={{height: '40px', width:'40px'}}/>
    </Link>
);

export default LogoSection;
