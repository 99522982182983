/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    show: false,
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const liveAiConversation = createSlice({
    name: 'liveAiConversation',
    initialState,
    reducers: {
        showLiveAiConversationPopup(state) {
            state.show = true;
        },

        hideLiveAiConversationPopup(state) {
            state.show = false;
        }
    }
});

export default liveAiConversation.reducer;

export const { showLiveAiConversationPopup, hideLiveAiConversationPopup } = liveAiConversation.actions;
