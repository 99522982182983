// store/slices/onboardingTourSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { StorageManager } from '../../utils/storageManager';

const initialState = {
    run: false,
    isReady: false,
    stepIndex: 0, // Keep track of the current step index
};

const onboardingTourSlice = createSlice({
    name: 'onboardingTour',
    initialState,
    reducers: {
        startTour: (state) => {
            state.run = true;
            state.isReady = true;
        },
        stopTour: (state) => {
            state.run = false;
            // state.isReady = false;
        },
        setStepIndex: (state, action) => {
            state.stepIndex = action.payload;
        },
        resetTour: (state) => {
            console.log("Resetting tour")
            state.run = false;
            state.isReady = false;
            state.stepIndex = 0;
            new StorageManager('ONBOARDING_TOUR_STEP_INDEX', 'OTHERS', false, 0).setStorage();
            new StorageManager('TOUR_GUIDE_COMPLETED', 'PERSIST', false, true).setStorage();
        },
    },
});

export const { startTour, stopTour, setStepIndex, resetTour } = onboardingTourSlice.actions;
export default onboardingTourSlice.reducer;
